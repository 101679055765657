.fixed-tool
	position: fixed
	top: 20%
	right: 10px
	z-index: 777
	ul
		padding-left: 0
		margin-bottom: 0
		list-style: none
		li
			+ li
				margin-top: 5px
			a
				display: flex
				align-items: center
				justify-content: center
				width: 50px
				height: 50px
				color: #fff
				font-size: 24px
				background: $mainColor
				transition: 0.3s all
				&:hover
					background: $blue-1
				&.form
					span
						display: none
				&.phone
					position: relative
					span
						display: flex
						align-items: center
						position: absolute
						right: 0
						top: 0
						height: 50px
						padding: 0 10px
						background: $blue-1
						white-space: nowrap
						font-size: 18px
						transition: 0.3s all
						opacity: 0
						pointer-events: none
					&:hover
						span
							right: 100%
							opacity: 1
							pointer-events: auto
	@media screen and ( max-width: 576px )
		bottom: 0
		top: auto
		width: 100%
		right: 0
		border-top: 1px solid #fff
		ul
			width: 100%
			display: flex
			li
				width: 50%
				+ li
					margin-top: 0
					border-left: 1px solid #fff
				a
					width: 100%
					align-items: center
					&.phone
						span
							position: static
							opacity: 1
							display: inline
							background: transparent
							height: auto
							font-size: 16px
							margin-left: 5px
							padding: 0
					&.form
						span
							display: inline
							font-size: 16px
							margin-left: 10px


.fancybox-container
	#form-contact-popup
		width: 600px
		.form-title
			font-size: r(28px)
			font-weight: 700
			margin-bottom: 20px
			text-align: center
		.form-group
			input[type="text"],textarea,select
				background: #fafafa
				border: 1px solid #e1e1e1
		.wrap-form
			.rcRefreshImage:before
				color: #333
			.RadCaptcha
				width: auto !important

.back-to-top
	position: fixed
	right: 10px
	bottom: 40px
	display: flex
	align-items: center
	justify-content: center
	width: 40px
	height: 40px
	background: $mainColor
	z-index: 666
	color: #fff
	opacity: 0
	font-size: r(24px)
	transition: opacity 0.3s
	svg
		display: none
	&.show
		opacity: 1