.home-3
	background-image: url(../img/home/bg-3.png)
	background-repeat: no-repeat
	background-size: cover
	.tabs-container
		margin-top: 10px
		.tabs-nav
			ul
				list-style: none
				padding-left: 0
				margin-bottom: -10px
				margin-left: -20px
				text-align: center
				li
					display: inline-block
					padding-left: 20px
					margin-bottom: 10px
					> *
						margin: 0
					a
						font-size: 17px
						font-weight: 500
						color: #999
						line-height: 1.4
						border-bottom: 1px solid transparent
					&.uk-active
						a
							color: $mainColor
							border-bottom: 1px solid $mainColor
		.uk-switcher
			margin-top: 40px
			.tabs-content
				display: block
				&.uk-active
					display: block !important
	.slider-mat-bang
		.swiper-prev
			left: 15%
		.swiper-next
			right: 15%
		.swiper-slide
			.img
				background: #fff
				transition: 0.3s all
				position: relative
				&:after
					content: url(../img/search-icon.png)
					position: absolute
					top: 50%
					left: 50%
					transform: translate(-50%,-50%)
					z-index: 3
					opacity: 1
					transition: 0.3s all
				&:before
					position: absolute
					content: ''
					top: 0
					left: 0
					width: 100%
					height: 100%
					background: #fff
					opacity: 0
					z-index: 2
					transition: 0.3s all
			&.swiper-slide-next,&.swiper-slide-prev
				.img
					transform: scaleY(0.8)
					&:after
						opacity: 0
					&:before
						opacity: 0.75
	@media screen and ( max-width: 1200.98px )
		.slider-mat-bang
			.swiper-prev
				left: 15px
			.swiper-next
				right: 15px
		