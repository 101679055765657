.subnav-wrapper
	padding-top: 1px
	.subnav
		display: flex
		justify-content: center

	ul
		text-align: center
		overflow-x: auto
		list-style: none
		padding-left: 0
		margin-bottom: 0
		display: flex
		li
			display: inline-flex
			+ li
				margin-left: 1px
			a
				display: flex
				align-items: center
				justify-content: center
				min-height: 55px
				background: $mainColor
				color: #fff
				padding: 0 30px
				text-transform: uppercase
				font-weight: 700
				font-size: 15px
				line-height: 1.4
				min-width: 200px
				white-space: nowrap
				padding-top: 5px
			&.active,&:hover
				a
					background: $blue-1
