.home-7
	background-image: url(../img/home/bg-7.jpg)
	background-repeat: no-repeat
	background-size: cover
	.uk-nav
		margin-bottom: 30px
		li
			display: inline-flex
			align-items: center
			font-size: 18px
			color: #666666
			+ li
				padding-left: 10px
				&:before
					content: "|"
					margin-right: 10px
	.btn-wrap
		margin-top: 30px

.news-list
	.news-big
		display: block
		position: relative
		border-radius: 20px 0 0 20px
		overflow: hidden
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2)
		.img
			// +img-scale(500 / 690 * 100%)
			height: 100%
			img
				width: 100%
				height: 100%
				object-fit: cover
		.caption
			position: absolute
			left: 0
			bottom: 0
			background: rgba($mainColor , 0.9)
			padding: 30px
			color: #fff
			max-width: 50%
			.date
				font-size: 12px
				letter-spacing: 0.1rem
				margin-bottom: 10px
			.title
				font-size: r(24px)
				line-height: 1.4
				margin: 0
			.arrow
				margin-top: 30px
				text-align: right
				font-size: 18px
	.news-small
		display: block
		background: #fff
		position: relative
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2)
		.img
			+img-scale(265 / 330 * 100%)
		.caption
			padding: 20px 30px
			.date
				font-size: 12px
				letter-spacing: 0.1rem
				margin-bottom: 10px
				color: #999
			.title
				font-size: r(20px)
				font-weight: 500
				line-height: 1.4
				margin-bottom: 60px
				margin-top: 0
		.arrow
			position: absolute
			bottom: 20px
			right: 30px
			font-size: 18px
			color: $blue-1
	@media screen and ( max-width: 768.98px )
		.news-big
			border-radius: 0
			.caption
				position: static
				max-width: none
				background: #fff
				color: #333
				padding: 20px
				.title
					font-size: 18px
		.news-small
			border-radius: 0
			.caption
				padding: 20px
				.title
					font-size: 14px
					margin-bottom: 30px
	@media screen and ( max-width: 576px )
		.uk-grid
			margin-left: -15px
			> *
				padding-left: 15px
			> .uk-grid-margin
				margin-top: 15px