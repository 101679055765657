.ngoai-khu
	background-image: url(../img/tien-ich/bg-1.png)
	background-repeat: no-repeat
	background-size: cover
	position: relative
	.subnav-wrapper
		position: absolute
		top: 0
		left: 50%
		transform: translateX(-50%)
		max-width: 100%
		.uk-container
			padding: 0
	.background
		background: rgba(42,92,170,.8)
		border-radius: 20px
		padding: 40px
		.desc
			font-size: 20px
			color: #fff
			text-transform: uppercase
			margin: 0
		.section-lead
			color: #fff
			margin: 20px 0
		.text
			p
				font-size: 18px
				color: #fff
				line-height: 25px
	@media screen and ( max-width: 768.98px )
		padding: 70px 0
		.background
			padding: 30px

.ngoai-khu-2
	background-image: url(../img/home/bg-2.png)
	background-repeat: no-repeat
	background-size: cover
	.nav
		text-align: center
		padding-left: 0
		margin: 0
		margin-bottom: 30px
		white-space: nowrap
		overflow-x: auto
		li
			display: inline-block
			+ li
				padding-left: 20px
			a
				display: block
				font-size: r(17px)
				font-weight: 500
				color: #999
				line-height: 1.5
				border-bottom: 1px solid transparent
				text-transform: uppercase
			&.uk-active
				a
					color: $mainColor
					border-bottom: 1px solid $mainColor
	.left
		padding-right: 0
		.image
			height: 100%
			border-radius: 20px 0 0 20px
			overflow: hidden
			img
				width: 100%
				height: 100%
				object-fit: cover
		@media (max-width: 1024.98px)
			padding-right: 15px
			.image
				border-radius: 20px 20px 0 0
	.list-item
		padding: 60px
		background: #fff
		border-radius: 0 20px 20px 0
		.box-wrapper
			padding-bottom: r(40px)
			border-bottom: 2px solid $blue-1
			.box
				display: flex
				align-items: center
				justify-content: center
				min-width: 140px
				height: 40px
				background: $blue-1
				padding: 0 15px
				p
					font-size: 20px
					text-transform: uppercase
					color: #fff
					margin: 0
		.item
			padding: 15px 0
			border-bottom: 1px solid #e1e1e1
			.title
				display: flex
				align-items: center
				em
					font-size: 14px
					color: $mainColor
					margin-right: 10px
				p
					font-size: r(18px)
					color: #666
					cursor: pointer
					margin: 0
					line-height: 1.4
			&:hover,&.active
				.title
					p
						color: $mainColor
						font-weight: 500
			.list-toggle
				display: none
	@media (max-width: 1024.98px)
		.list-item
			border-radius: 0 0 20px 20px
			padding: 30px