.news-detail
	.section-lead
		font-size: 36px
	.icon-social
		display: flex
		align-items: center
		justify-content: space-between
		padding-bottom: 20px
		time
			font-size: 12px
			color: #999
	.content
		padding:  30px 0 45px 0
		border-top: 1px solid #e1e1e1
		border-bottom: 1px solid #e1e1e1
		font-size: 18px
		line-height: 1.5
	.news-other
		margin-top: 40px
		.uk-slider-items
			padding: 5px
		.slider-nav
			@media screen and ( min-width: 1600px )
				&.uk-position-center-left-out
					margin-right: 20px
				&.uk-position-center-right-out
					margin-left: 20px
