.about-2
	background-image: url(../img/about/bg-2.jpg)
	background-repeat: no-repeat
	background-size: cover
	.content
		margin-top: 30px
		font-size: r(18px)
		line-height: 1.4
		table
			width: 100%
			border: 1px solid #e1e1e1
			border-top: 2px solid $mainColor
			tr
				td
					border-bottom: 1px solid #e1e1e1
					padding: 20px 25px
					background: #fff
	@media screen and ( max-width: 576px )
		.content
			table
				tr
					td
						padding: 8px 16px