header
	position: fixed
	z-index: 999
	left: 0
	top: 0
	width: 100%
	background: rgba(#fff , 0.85)
	min-height: 80px
	display: flex
	align-items: center
	transition: 0.3s all
	&.scrolling
		min-height: 50px
		.logo
			img,svg
				max-height: 70px
			svg
				height: 70px

	.uk-container
		width: 100%
		max-width: 1230px

	.logo
		position: absolute
		top: 0
		left: 50%
		transform: translateX(-50%)
		img,svg
			max-height: 120px
			transition: 0.3s all
		svg
			height: 120px
			background: #fff

	.menu
		ul
			li
				+ li
					margin-left: 40px
				a
					min-height: 50px
					display: flex
					align-items: center
					padding: 0
					font-weight: 500
					text-transform: uppercase
					em
						font-size: 20px
				&.active
					a
						color: $blue-1
	.right-header
		margin-left: auto

	.view-360-wrapper
		margin-left: 50px

	.language-wrapper
		margin-left: 50px

	.search
		margin-left: 10px
		font-size: 18px
		color: #989898

	.view-360
		a
			strong
				margin-left: 10px
				+text-gradient($gradient-1)
				text-transform: uppercase

	.language
		font-size: 14px
		font-weight: 500
		color: #989898

	.menu-mobile-wrapper
		margin-left: 10px
		.menu-toggle
			display: flex
			align-items: center
			min-height: 50px
			font-size: 24px
	.uk-offcanvas-bar
		background: $mainColor
		.uk-offcanvas-close
			svg
				line
					stroke: #fff !important
	.mobile-menu
		.menu
			ul
				flex-direction: column
				li
					padding-bottom: 15px
					margin-bottom: 15px
					border-bottom: 1px dashed #fff
					+ li
						margin-left: 0
					a
						min-height: auto
						color: #fff

	@media screen and ( min-width: 1024.98px )
		.menu-mobile-wrapper
			display: none

	@media screen and ( max-width: 1024.98px )
		.view-360-wrapper
			margin-left: 0
		.right-header
			margin-left: 0
			width: 100%
		.language-wrapper
			margin-left: auto
	@media screen and ( max-width: 576px )
		min-height: 60px
		.logo
			img,svg
				max-height: 80px
			svg
				height: 80px
		.view-360
			strong
				display: none
		.uk-offcanvas-bar
			width: 100%
			left: -100%

#modal-search
	.uk-modal-close-full
		svg
			width: 30px
			height: 30px
	.uk-search
		display: flex
		position: relative
		padding: 0 15px
		input
			border-bottom: 2px solid #e5e5e5
			padding-right: 48px
			color: #333
		button
			background: #fff
			color: #333
			font-size: 36px
			border: 0
			position: absolute
			right: 0
			top: 50%
			transform: translateY(-50%)
			display: flex
			align-items: center
			justify-content: center
	@media (max-width: 576px)
		.uk-search
			width: auto