.home-5
	background-repeat: no-repeat
	background-size: cover
	background-position: center center
	min-height: 100vh
	.content-wrapper
		padding: 40px
		border-radius: 25px
		background: rgba(#fff , 0.85)
		ul
			list-style: none
			padding-left: 0
			width: 400px
			max-width: 100%
			margin: 0 auto
			border-radius: 20px
			display: flex
			overflow: hidden
			flex-wrap: wrap
			li
				display: inline-block
				width: 50%
				> *
					margin-bottom: 0
				a
					display: flex
					align-items: center
					justify-content: center
					font-weight: 700
					font-size: 14px
					letter-spacing: 0.1rem
					min-height: 40px
					padding: 8px
					line-height: 1.3
					width: 100%
					color: #fff
					text-transform: uppercase
				&:first-child
					background: $blue-1
				&:last-child
					background: $mainColor
	@media screen and ( max-width: 768.98px )
		min-height: auto
		.content-wrapper
			padding: 20px
	@media screen and ( max-width: 576px )
		.content-wrapper
			ul
				li
					width: 100%
					a
						padding: 8px 33px