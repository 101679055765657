.news
	.news-list
		.news-big
			border-radius: 20px 0 0 0
		.news-small
			.caption 
				.title
					margin-bottom: 10px
					line-height: 1.3
				.brief
					line-height: 1.4
					margin-bottom: 40px
					+line(2)
		.wrapper
			.news-small
				overflow: hidden
			&:nth-child(3)
				.news-small
					border-radius: 0 20px 0 0
			&:nth-child(4)
				.news-small
					border-radius: 0 0 0 20px
			&:last-child
				.news-small
					border-radius: 0 0 20px 0
				