.home-2
	position: relative
	background-image: url(../img/home/bg-2.png)
	background-repeat: no-repeat
	background-size: cover
	overflow: hidden
	padding-top: 0 !important
	.img
		min-height: 1080px
		img
			height: 100%
			object-fit: cover
			@media screen and ( min-width: 1919.98 )
				width: 100%
				
	.content-container
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%,-50%)
		width: 100%
		> .uk-grid
			height: 100%
		@media screen and ( min-width: 1600px )
			top: 45%
	.content-wrapper
		padding: 30px 60px
		background: #fff
		border-radius: 20px
		box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.06)
		position: relative
		left: -60px
	.content
		text-align: center
		ul
			padding-left: 0
			list-style: none
			display: inline-flex
			margin-left: -20px
			position: relative
			margin-bottom: 0
			flex-wrap: wrap
			max-width: 560px
			justify-content: center
			li
				position: relative
				z-index: 2
				padding-left: 20px
				width: auto
				&.uk-grid-margin
					margin-top: 20px
				&:not(.uk-first-column)
					&:before
						position: absolute
						content: ''
						top: 50%
						left: 0
						width: 20px
						transform: translateY(-50%)
						height: 1px
						background: $mainColor
						z-index: 1
				p
					display: flex
					align-items: center
					justify-content: center
					flex-direction: column
					margin-bottom: 0
					width: 120px
					height: 120px
					border-radius: 50%
					background: #fff
					border: 1px solid $mainColor
					text-align: center
					padding: 0 10px
					.number
						font-size: r(36px)
						font-weight: 900
						color: $mainColor
						font-family: $font-1
						text-transform: uppercase
						display: block
						margin-bottom: 5px
					.text
						font-size: 13px
						line-height: 1.4
	.btn-wrap
		margin-top: 20px
	@media screen and ( max-width: 1200.98px )
		.img
			min-height: auto
			img
				width: 100%
		.content-container
			position: static
			transform: none
			max-width: none
			padding: 0
		.content-wrapper
			left: 0
			border-radius: 0
	@media screen and ( max-width: 768.98px )
		.content-wrapper
			padding: 30px 15px
		.content
			text-align: center
			ul
				li
					&:before
						display: none !important
	@media screen and ( max-width: 768.98px )
		.content
			ul
				li
					p
						width: 110px
						height: 110px
						.number
							font-size: 24px
						.text
							font-size: 12px
	