.mat-bang-3
	.wrapper-2
		padding-left: 8.33%
	.caption-list
		ul
			list-style: none
			padding-left: 0
			margin-bottom: 0
			margin-top: 30px
			li
				display: inline-flex
				align-items: center
				width: 100%
				+ li
					margin-top: 20px
				.color
					display: block
					height: 40px
					width: 115px
				.text
					margin-left: 20px
		.btn-primary
			margin-top: 50px
			width: 225px
			margin-left: auto
			span
				margin-right: 10px
				font-size: 20px
	@media screen and ( max-width: 1024.98px )
		.caption
			padding-left: 0