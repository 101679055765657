$global-margin: 20px !default;
$accordion-item-margin-top: $global-margin !default;
$global-medium-font-size: 1.25rem !default;
$accordion-title-font-size: $global-medium-font-size !default;
$accordion-title-line-height: 1.4 !default;
$global-emphasis-color: #333 !default;
$accordion-title-color: $global-emphasis-color !default;
$global-color: #666 !default;
$accordion-title-hover-color: $global-color !default;
$accordion-content-margin-top: $global-margin !default;
$global-inverse-color: #fff !default;
$inverse-global-emphasis-color: $global-inverse-color !default;
$inverse-accordion-title-color: $inverse-global-emphasis-color !default;
$inverse-global-color: rgba($global-inverse-color, 0.7) !default;
$inverse-accordion-title-hover-color: $inverse-global-color !default;
$alert-margin-vertical: $global-margin !default;
$global-small-gutter: 15px !default;
$alert-padding: $global-small-gutter !default;
$alert-padding-right: $alert-padding+14px !default;
$global-muted-background: #f8f8f8 !default;
$alert-background: $global-muted-background !default;
$alert-color: $global-color !default;
$alert-close-top: $alert-padding+5px !default;
$alert-close-right: $alert-padding !default;
$global-primary-background: #1e87f0 !default;
$alert-primary-background: lighten(mix(white, $global-primary-background, 40%), 20%) !default;
$alert-primary-color: $global-primary-background !default;
$global-success-background: #32d296 !default;
$alert-success-background: lighten(mix(white, $global-success-background, 40%), 25%) !default;
$alert-success-color: $global-success-background !default;
$global-warning-background: #faa05a !default;
$alert-warning-background: lighten(mix(white, $global-warning-background, 45%), 15%) !default;
$alert-warning-color: $global-warning-background !default;
$global-danger-background: #f0506e !default;
$alert-danger-background: lighten(mix(white, $global-danger-background, 40%), 20%) !default;
$alert-danger-color: $global-danger-background !default;
$global-gutter: 30px !default;
$align-margin-horizontal: $global-gutter !default;
$align-margin-vertical: $global-gutter !default;
$global-medium-gutter: 40px !default;
$align-margin-horizontal-l: $global-medium-gutter !default;
$animation-duration: 0.5s !default;
$animation-fade-duration: 0.8s !default;
$animation-stroke-duration: 2s !default;
$animation-kenburns-duration: 15s !default;
$animation-fast-duration: 0.1s !default;
$animation-slide-small-translate: 10px !default;
$animation-slide-medium-translate: 50px !default;
$global-large-margin: 70px !default;
$article-margin-top: $global-large-margin !default;
$global-xxlarge-font-size: 2.625rem !default;
$article-title-font-size-m: $global-xxlarge-font-size !default;
$article-title-font-size: $article-title-font-size-m * 0.85 !default;
$article-title-line-height: 1.2 !default;
$global-small-font-size: 0.875rem !default;
$article-meta-font-size: $global-small-font-size !default;
$article-meta-line-height: 1.4 !default;
$global-muted-color: #999 !default;
$article-meta-color: $global-muted-color !default;
$inverse-global-muted-color: rgba($global-inverse-color, 0.5) !default;
$inverse-article-meta-color: $inverse-global-muted-color !default;
$global-background: #fff !default;
$background-default-background: $global-background !default;
$background-muted-background: $global-muted-background !default;
$background-primary-background: $global-primary-background !default;
$global-secondary-background: #222 !default;
$background-secondary-background: $global-secondary-background !default;
$badge-size: 22px !default;
$badge-padding-vertical: 0 !default;
$badge-padding-horizontal: 5px !default;
$badge-border-radius: 500px !default;
$badge-background: $global-primary-background !default;
$badge-color: $global-inverse-color !default;
$badge-font-size: $global-small-font-size !default;
$badge-hover-color: $global-inverse-color !default;
$inverse-global-primary-background: $global-inverse-color !default;
$inverse-badge-background: $inverse-global-primary-background !default;
$inverse-global-inverse-color: $global-color !default;
$inverse-badge-color: $inverse-global-inverse-color !default;
$inverse-badge-hover-color: $inverse-global-inverse-color !default;
$base-body-background: $global-background !default;
$global-font-family: 'Roboto',
sans-serif !important;
$base-body-font-family: $global-font-family !default;
$base-body-font-weight: normal !default;
$global-font-size: 16px !default;
$base-body-font-size: $global-font-size !default;
$global-line-height: 1.5 !default;
$base-body-line-height: $global-line-height !default;
$base-body-color: $global-color !default;
$global-link-color: #1e87f0 !default;
$base-link-color: $global-link-color !default;
$base-link-text-decoration: none !default;
$global-link-hover-color: #0f6ecd !default;
$base-link-hover-color: $global-link-hover-color !default;
$base-link-hover-text-decoration: underline !default;
$base-strong-font-weight: bolder !default;
$base-code-font-size: $global-small-font-size !default;
$base-code-font-family: Consolas,
monaco,
monospace !default;
$base-code-color: $global-danger-background !default;
$base-em-color: $global-danger-background !default;
$base-ins-background: #ffd !default;
$base-ins-color: $global-color !default;
$base-mark-background: #ffd !default;
$base-mark-color: $global-color !default;
$base-quote-font-style: italic !default;
$base-small-font-size: 80% !default;
$base-margin-vertical: $global-margin !default;
$base-heading-font-family: $global-font-family !default;
$base-heading-font-weight: normal !default;
$base-heading-color: $global-emphasis-color !default;
$base-heading-text-transform: none !default;
$global-medium-margin: 40px !default;
$base-heading-margin-top: $global-medium-margin !default;
$base-h1-font-size-m: $global-xxlarge-font-size !default;
$base-h1-font-size: $base-h1-font-size-m * 0.85 !default;
$base-h1-line-height: 1.2 !default;
$global-xlarge-font-size: 2rem !default;
$base-h2-font-size-m: $global-xlarge-font-size !default;
$base-h2-font-size: $base-h2-font-size-m * 0.85 !default;
$base-h2-line-height: 1.3 !default;
$global-large-font-size: 1.5rem !default;
$base-h3-font-size: $global-large-font-size !default;
$base-h3-line-height: 1.4 !default;
$base-h4-font-size: $global-medium-font-size !default;
$base-h4-line-height: 1.4 !default;
$base-h5-font-size: $global-font-size !default;
$base-h5-line-height: 1.4 !default;
$base-h6-font-size: $global-small-font-size !default;
$base-h6-line-height: 1.4 !default;
$base-list-padding-left: 30px !default;
$base-hr-margin-vertical: $global-margin !default;
$global-border-width: 1px !default;
$base-hr-border-width: $global-border-width !default;
$global-border: #e5e5e5 !default;
$base-hr-border: $global-border !default;
$base-blockquote-font-size: $global-medium-font-size !default;
$base-blockquote-line-height: 1.5 !default;
$base-blockquote-font-style: italic !default;
$base-blockquote-margin-vertical: $global-margin !default;
$global-small-margin: 10px !default;
$base-blockquote-footer-margin-top: $global-small-margin !default;
$base-blockquote-footer-font-size: $global-small-font-size !default;
$base-blockquote-footer-line-height: 1.5 !default;
$base-pre-font-size: $global-small-font-size !default;
$base-pre-line-height: 1.5 !default;
$base-pre-font-family: $base-code-font-family !default;
$base-pre-color: $global-color !default;
$base-selection-background: #39f !default;
$base-selection-color: $global-inverse-color !default;
$inverse-base-color: $inverse-global-color !default;
$inverse-base-link-color: $inverse-global-emphasis-color !default;
$inverse-base-link-hover-color: $inverse-global-emphasis-color !default;
$inverse-base-code-color: $inverse-global-color !default;
$inverse-base-em-color: $inverse-global-emphasis-color !default;
$inverse-base-heading-color: $inverse-global-emphasis-color !default;
$inverse-global-border: rgba($global-inverse-color, 0.2) !default;
$inverse-base-hr-border: $inverse-global-border !default;
$breadcrumb-item-font-size: $global-small-font-size !default;
$breadcrumb-item-color: $global-muted-color !default;
$breadcrumb-item-hover-color: $global-color !default;
$breadcrumb-item-hover-text-decoration: none !default;
$breadcrumb-item-active-color: $global-color !default;
$breadcrumb-divider: "/" !default;
$breadcrumb-divider-margin-horizontal: 20px !default;
$breadcrumb-divider-color: $global-muted-color !default;
$inverse-breadcrumb-item-color: $inverse-global-muted-color !default;
$inverse-breadcrumb-item-hover-color: $inverse-global-color !default;
$inverse-breadcrumb-item-active-color: $inverse-global-color !default;
$inverse-breadcrumb-divider-color: $inverse-global-muted-color !default;
$global-control-height: 40px !default;
$button-border-width: $global-border-width !default;
$button-line-height: $global-control-height - ($button-border-width * 2) !default;
$global-control-small-height: 30px !default;
$button-small-line-height: $global-control-small-height - ($button-border-width * 2) !default;
$global-control-large-height: 55px !default;
$button-large-line-height: $global-control-large-height - ($button-border-width * 2) !default;
$button-font-size: $global-small-font-size !default;
$button-small-font-size: $global-small-font-size !default;
$button-large-font-size: $global-small-font-size !default;
$button-padding-horizontal: $global-gutter !default;
$button-small-padding-horizontal: $global-small-gutter !default;
$button-large-padding-horizontal: $global-medium-gutter !default;
$button-default-background: transparent !default;
$button-default-color: $global-emphasis-color !default;
$button-default-hover-background: transparent !default;
$button-default-hover-color: $global-emphasis-color !default;
$button-default-active-background: transparent !default;
$button-default-active-color: $global-emphasis-color !default;
$button-primary-background: $global-primary-background !default;
$button-primary-color: $global-inverse-color !default;
$button-primary-hover-background: darken($button-primary-background, 5%) !default;
$button-primary-hover-color: $global-inverse-color !default;
$button-primary-active-background: darken($button-primary-background, 10%) !default;
$button-primary-active-color: $global-inverse-color !default;
$button-secondary-background: $global-secondary-background !default;
$button-secondary-color: $global-inverse-color !default;
$button-secondary-hover-background: darken($button-secondary-background, 5%) !default;
$button-secondary-hover-color: $global-inverse-color !default;
$button-secondary-active-background: darken($button-secondary-background, 10%) !default;
$button-secondary-active-color: $global-inverse-color !default;
$button-danger-background: $global-danger-background !default;
$button-danger-color: $global-inverse-color !default;
$button-danger-hover-background: darken($button-danger-background, 5%) !default;
$button-danger-hover-color: $global-inverse-color !default;
$button-danger-active-background: darken($button-danger-background, 10%) !default;
$button-danger-active-color: $global-inverse-color !default;
$button-disabled-background: transparent !default;
$button-disabled-color: $global-muted-color !default;
$button-text-line-height: $global-line-height !default;
$button-text-color: $global-emphasis-color !default;
$button-text-hover-color: $global-emphasis-color !default;
$button-text-disabled-color: $global-muted-color !default;
$button-link-line-height: $global-line-height !default;
$button-link-color: $global-link-color !default;
$button-link-hover-color: $global-link-hover-color !default;
$button-link-hover-text-decoration: underline !default;
$button-link-disabled-color: $global-muted-color !default;
$inverse-button-default-background: transparent !default;
$inverse-button-default-color: $inverse-global-emphasis-color !default;
$inverse-button-default-hover-background: transparent !default;
$inverse-button-default-hover-color: $inverse-global-emphasis-color !default;
$inverse-button-default-active-background: transparent !default;
$inverse-button-default-active-color: $inverse-global-emphasis-color !default;
$inverse-button-primary-background: $inverse-global-primary-background !default;
$inverse-button-primary-color: $inverse-global-inverse-color !default;
$inverse-button-primary-hover-background: darken($inverse-button-primary-background, 5%) !default;
$inverse-button-primary-hover-color: $inverse-global-inverse-color !default;
$inverse-button-primary-active-background: darken($inverse-button-primary-background, 10%) !default;
$inverse-button-primary-active-color: $inverse-global-inverse-color !default;
$inverse-button-secondary-background: $inverse-global-primary-background !default;
$inverse-button-secondary-color: $inverse-global-inverse-color !default;
$inverse-button-secondary-hover-background: darken($inverse-button-secondary-background, 5%) !default;
$inverse-button-secondary-hover-color: $inverse-global-inverse-color !default;
$inverse-button-secondary-active-background: darken($inverse-button-secondary-background, 10%) !default;
$inverse-button-secondary-active-color: $inverse-global-inverse-color !default;
$inverse-button-text-color: $inverse-global-emphasis-color !default;
$inverse-button-text-hover-color: $inverse-global-emphasis-color !default;
$inverse-button-text-disabled-color: $inverse-global-muted-color !default;
$inverse-button-link-color: $inverse-global-muted-color !default;
$inverse-button-link-hover-color: $inverse-global-color !default;
$card-body-padding-horizontal: $global-gutter !default;
$card-body-padding-vertical: $global-gutter !default;
$card-body-padding-horizontal-l: $global-medium-gutter !default;
$card-body-padding-vertical-l: $global-medium-gutter !default;
$card-header-padding-horizontal: $global-gutter !default;
$card-header-padding-vertical: round($global-gutter / 2) !default;
$card-header-padding-horizontal-l: $global-medium-gutter !default;
$card-header-padding-vertical-l: round($global-medium-gutter / 2) !default;
$card-footer-padding-horizontal: $global-gutter !default;
$card-footer-padding-vertical: ($global-gutter / 2) !default;
$card-footer-padding-horizontal-l: $global-medium-gutter !default;
$card-footer-padding-vertical-l: round($global-medium-gutter / 2) !default;
$card-title-font-size: $global-large-font-size !default;
$card-title-line-height: 1.4 !default;
$card-badge-top: $global-gutter !default;
$card-badge-right: $card-badge-top !default;
$card-hover-background: $global-background !default;
$card-default-background: $global-background !default;
$card-default-color: $global-color !default;
$card-default-title-color: $global-emphasis-color !default;
$card-default-hover-background: $card-default-background !default;
$card-primary-background: $global-primary-background !default;
$card-primary-color: $global-inverse-color !default;
$card-primary-title-color: $card-primary-color !default;
$card-primary-hover-background: $card-primary-background !default;
$card-primary-color-mode: light !default;
$card-secondary-background: $global-secondary-background !default;
$card-secondary-color: $global-inverse-color !default;
$card-secondary-title-color: $card-secondary-color !default;
$card-secondary-hover-background: $card-secondary-background !default;
$card-secondary-color-mode: light !default;
$card-small-body-padding-horizontal: $global-margin !default;
$card-small-body-padding-vertical: $global-margin !default;
$card-small-header-padding-horizontal: $global-margin !default;
$card-small-header-padding-vertical: round($global-margin / 1.5) !default;
$card-small-footer-padding-horizontal: $global-margin !default;
$card-small-footer-padding-vertical: round($global-margin / 1.5) !default;
$global-large-gutter: 70px !default;
$card-large-body-padding-horizontal-l: $global-large-gutter !default;
$card-large-body-padding-vertical-l: $global-large-gutter !default;
$card-large-header-padding-horizontal-l: $global-large-gutter !default;
$card-large-header-padding-vertical-l: round($global-large-gutter / 2) !default;
$card-large-footer-padding-horizontal-l: $global-large-gutter !default;
$card-large-footer-padding-vertical-l: round($global-large-gutter / 2) !default;
$close-color: $global-muted-color !default;
$close-hover-color: $global-color !default;
$inverse-close-color: $inverse-global-muted-color !default;
$inverse-close-hover-color: $inverse-global-color !default;
$column-gutter: $global-gutter !default;
$column-gutter-l: $global-medium-gutter !default;
$column-divider-rule-color: $global-border !default;
$column-divider-rule-width: 1px !default;
$inverse-column-divider-rule-color: $inverse-global-border !default;
$comment-header-margin-bottom: $global-margin !default;
$comment-title-font-size: $global-medium-font-size !default;
$comment-title-line-height: 1.4 !default;
$comment-meta-font-size: $global-small-font-size !default;
$comment-meta-line-height: 1.4 !default;
$comment-meta-color: $global-muted-color !default;
$comment-list-margin-top: $global-large-margin !default;
$comment-list-padding-left: 30px !default;
$comment-list-padding-left-m: 100px !default;
$container-max-width: 1200px !default;
$container-xsmall-max-width: 750px !default;
$container-small-max-width: 900px !default;
$container-large-max-width: 1600px !default;
$container-padding-horizontal: 15px !default;
$container-padding-horizontal-s: $global-gutter !default;
$container-padding-horizontal-m: $global-medium-gutter !default;
$countdown-item-line-height: 70px !default;
$countdown-number-font-size: 2rem !default;
$countdown-number-font-size-s: 4rem !default;
$countdown-number-font-size-m: 6rem !default;
$countdown-separator-font-size: 1rem !default;
$countdown-separator-font-size-s: 2rem !default;
$countdown-separator-font-size-m: 3rem !default;
$description-list-term-color: $global-emphasis-color !default;
$description-list-term-margin-top: $global-margin !default;
$description-list-divider-term-margin-top: $global-margin !default;
$description-list-divider-term-border-width: $global-border-width !default;
$description-list-divider-term-border: $global-border !default;
$divider-margin-vertical: $global-margin !default;
$divider-icon-width: 50px !default;
$divider-icon-height: 20px !default;
$divider-icon-color: $global-border !default;
$divider-icon-line-top: 50% !default;
$divider-icon-line-width: 100% !default;
$divider-icon-line-border-width: $global-border-width !default;
$divider-icon-line-border: $global-border !default;
$internal-divider-icon-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22none%22%20stroke%3D%22#000%22%20stroke-width%3D%222%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%227%22%20%2F%3E%0A%3C%2Fsvg%3E%0A" !default;
$divider-small-width: 100px !default;
$divider-small-border-width: $global-border-width !default;
$divider-small-border: $global-border !default;
$divider-vertical-height: 100px !default;
$divider-vertical-border-width: $global-border-width !default;
$divider-vertical-border: $global-border !default;
$inverse-divider-icon-color: $inverse-global-border !default;
$inverse-divider-icon-line-border: $inverse-global-border !default;
$inverse-divider-small-border: $inverse-global-border !default;
$inverse-divider-vertical-border: $inverse-global-border !default;
$dotnav-margin-horizontal: 12px !default;
$dotnav-margin-vertical: $dotnav-margin-horizontal !default;
$dotnav-item-width: 10px !default;
$dotnav-item-height: $dotnav-item-width !default;
$dotnav-item-border-radius: 50% !default;
$dotnav-item-background: transparent !default;
$dotnav-item-hover-background: rgba($global-color, 0.6) !default;
$dotnav-item-onclick-background: rgba($global-color, 0.2) !default;
$dotnav-item-active-background: rgba($global-color, 0.6) !default;
$inverse-dotnav-item-background: transparent !default;
$inverse-dotnav-item-hover-background: rgba($inverse-global-color, 0.9) !default;
$inverse-dotnav-item-onclick-background: rgba($inverse-global-color, 0.5) !default;
$inverse-dotnav-item-active-background: rgba($inverse-global-color, 0.9) !default;
$global-z-index: 1000 !default;
$drop-z-index: $global-z-index+20 !default;
$drop-width: 300px !default;
$drop-margin: $global-margin !default;
$dropdown-z-index: $global-z-index+20 !default;
$dropdown-min-width: 200px !default;
$dropdown-padding: 25px !default;
$dropdown-background: $global-background !default;
$dropdown-color: $global-color !default;
$dropdown-margin: $global-small-margin !default;
$dropdown-nav-item-color: $global-muted-color !default;
$dropdown-nav-item-hover-color: $global-color !default;
$dropdown-nav-header-color: $global-emphasis-color !default;
$dropdown-nav-divider-border-width: $global-border-width !default;
$dropdown-nav-divider-border: $global-border !default;
$dropdown-nav-sublist-item-color: $global-muted-color !default;
$dropdown-nav-sublist-item-hover-color: $global-color !default;
$form-range-thumb-height: 15px !default;
$form-range-thumb-border-radius: 500px !default;
$form-range-thumb-background: $global-background !default;
$form-range-track-height: 3px !default;
$form-range-track-background: darken($global-muted-background, 5%) !default;
$form-range-track-focus-background: darken($global-muted-background, 15%) !default;
$form-height: $global-control-height !default;
$form-border-width: $global-border-width !default;
$form-line-height: $form-height - (2* $form-border-width) !default;
$form-padding-horizontal: 10px !default;
$form-padding-vertical: 4px !default;
$form-background: $global-background !default;
$form-color: $global-color !default;
$form-focus-background: $global-background !default;
$form-focus-color: $global-color !default;
$form-disabled-background: $global-muted-background !default;
$form-disabled-color: $global-muted-color !default;
$form-placeholder-color: $global-muted-color !default;
$form-small-height: $global-control-small-height !default;
$form-small-padding-horizontal: 8px !default;
$form-small-line-height: $form-small-height - (2* $form-border-width) !default;
$form-small-font-size: $global-small-font-size !default;
$form-large-height: $global-control-large-height !default;
$form-large-padding-horizontal: 12px !default;
$form-large-line-height: $form-large-height - (2* $form-border-width) !default;
$form-large-font-size: $global-medium-font-size !default;
$form-danger-color: $global-danger-background !default;
$form-success-color: $global-success-background !default;
$form-width-xsmall: 50px !default;
$form-width-small: 130px !default;
$form-width-medium: 200px !default;
$form-width-large: 500px !default;
$form-select-padding-right: 20px !default;
$form-select-icon-color: $global-color !default;
$form-select-option-color: #444 !default;
$form-select-disabled-icon-color: $global-muted-color !default;
$form-datalist-padding-right: 20px !default;
$form-datalist-icon-color: $global-color !default;
$form-radio-size: 16px !default;
$form-radio-margin-top: -4px !default;
$form-radio-background: transparent !default;
$form-radio-checked-background: $global-primary-background !default;
$form-radio-checked-icon-color: $global-inverse-color !default;
$form-radio-checked-focus-background: darken($global-primary-background, 10%) !default;
$form-radio-disabled-background: $global-muted-background !default;
$form-radio-disabled-icon-color: $global-muted-color !default;
$form-legend-font-size: $global-large-font-size !default;
$form-legend-line-height: 1.4 !default;
$form-stacked-margin-bottom: 5px !default;
$form-horizontal-label-width: 200px !default;
$form-horizontal-label-margin-top: 7px !default;
$form-horizontal-controls-margin-left: 215px !default;
$form-horizontal-controls-text-padding-top: 7px !default;
$form-icon-width: $form-height !default;
$form-icon-color: $global-muted-color !default;
$form-icon-hover-color: $global-color !default;
$internal-form-select-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22#000%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22#000%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A" !default;
$internal-form-datalist-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22#000%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A" !default;
$internal-form-radio-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22#000%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E" !default;
$internal-form-checkbox-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22#000%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A" !default;
$internal-form-checkbox-indeterminate-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22#000%22%20x%3D%223%22%20y%3D%228%22%20width%3D%2210%22%20height%3D%221%22%20%2F%3E%0A%3C%2Fsvg%3E" !default;
$inverse-global-muted-background: rgba($global-inverse-color, 0.1) !default;
$inverse-form-background: $inverse-global-muted-background !default;
$inverse-form-color: $inverse-global-color !default;
$inverse-form-focus-background: $inverse-global-muted-background !default;
$inverse-form-focus-color: $inverse-global-color !default;
$inverse-form-placeholder-color: $inverse-global-muted-color !default;
$inverse-form-select-icon-color: $inverse-global-color !default;
$inverse-form-datalist-icon-color: $inverse-global-color !default;
$inverse-form-radio-background: darken($inverse-global-muted-background, 5%) !default;
$inverse-form-radio-checked-background: $inverse-global-primary-background !default;
$inverse-form-radio-checked-icon-color: $inverse-global-inverse-color !default;
$inverse-form-radio-checked-focus-background: darken($inverse-global-primary-background, 10%) !default;
$inverse-form-icon-color: $inverse-global-muted-color !default;
$inverse-form-icon-hover-color: $inverse-global-color !default;
$grid-gutter-horizontal: $global-gutter !default;
$grid-gutter-vertical: $grid-gutter-horizontal !default;
$grid-gutter-horizontal-l: $global-medium-gutter !default;
$grid-gutter-vertical-l: $grid-gutter-horizontal-l !default;
$grid-small-gutter-horizontal: $global-small-gutter !default;
$grid-small-gutter-vertical: $grid-small-gutter-horizontal !default;
$grid-medium-gutter-horizontal: $global-gutter !default;
$grid-medium-gutter-vertical: $grid-medium-gutter-horizontal !default;
$grid-large-gutter-horizontal: $global-medium-gutter !default;
$grid-large-gutter-vertical: $grid-large-gutter-horizontal !default;
$grid-large-gutter-horizontal-l: $global-large-gutter !default;
$grid-large-gutter-vertical-l: $grid-large-gutter-horizontal-l !default;
$grid-divider-border-width: $global-border-width !default;
$grid-divider-border: $global-border !default;
$inverse-grid-divider-border: $inverse-global-border !default;
$heading-medium-font-size-l: 4rem !default;
$heading-small-font-size-m: $heading-medium-font-size-l * 0.8125 !default;
$heading-small-font-size: $heading-small-font-size-m * 0.8 !default;
$heading-medium-font-size-m: $heading-medium-font-size-l * 0.875 !default;
$heading-medium-font-size: $heading-medium-font-size-m * 0.825 !default;
$heading-large-font-size-m: $heading-medium-font-size-l !default;
$heading-large-font-size: $heading-large-font-size-m * 0.85 !default;
$heading-xlarge-font-size: $heading-large-font-size-m !default;
$heading-large-font-size-l: 6rem !default;
$heading-xlarge-font-size-m: $heading-large-font-size-l !default;
$heading-2xlarge-font-size: $heading-xlarge-font-size-m !default;
$heading-xlarge-font-size-l: 8rem !default;
$heading-2xlarge-font-size-m: $heading-xlarge-font-size-l !default;
$heading-2xlarge-font-size-l: 11rem !default;
$heading-small-line-height: 1.2 !default;
$heading-medium-line-height: 1.1 !default;
$heading-large-line-height: 1.1 !default;
$heading-xlarge-line-height: 1 !default;
$heading-2xlarge-line-height: 1 !default;
$heading-divider-padding-bottom: unquote('calc(5px + 0.1em)') !default;
$heading-divider-border-width: unquote('calc(0.2px + 0.05em)') !default;
$heading-divider-border: $global-border !default;
$heading-bullet-top: unquote('calc(-0.1 * 1em)') !default;
$heading-bullet-height: unquote('calc(4px + 0.7em)') !default;
$heading-bullet-margin-right: unquote('calc(5px + 0.2em)') !default;
$heading-bullet-border-width: unquote('calc(5px + 0.1em)') !default;
$heading-bullet-border: $global-border !default;
$heading-line-top: 50% !default;
$heading-line-border-width: unquote('calc(0.2px + 0.05em)') !default;
$heading-line-height: $heading-line-border-width !default;
$heading-line-width: 2000px !default;
$heading-line-border: $global-border !default;
$heading-line-margin-horizontal: unquote('calc(5px + 0.3em)') !default;
$heading-primary-font-size-l: 3.75rem !default;
$heading-primary-line-height-l: 1.1 !default;
$heading-primary-font-size-m: $heading-primary-font-size-l * 0.9 !default;
$heading-primary-font-size: $heading-primary-font-size-l * 0.8 !default;
$heading-primary-line-height: 1.2 !default;
$heading-hero-font-size-l: 8rem !default;
$heading-hero-line-height-l: 1 !default;
$heading-hero-font-size-m: $heading-hero-font-size-l * 0.75 !default;
$heading-hero-line-height-m: 1 !default;
$heading-hero-font-size: $heading-hero-font-size-l * 0.5 !default;
$heading-hero-line-height: 1.1 !default;
$inverse-heading-divider-border: $inverse-global-border !default;
$inverse-heading-bullet-border: $inverse-global-border !default;
$inverse-heading-line-border: $inverse-global-border !default;
$height-small-height: 150px !default;
$height-medium-height: 300px !default;
$height-large-height: 450px !default;
$icon-image-size: 20px !default;
$icon-link-color: $global-muted-color !default;
$icon-link-hover-color: $global-color !default;
$icon-link-active-color: darken($global-color, 5%) !default;
$icon-button-size: 36px !default;
$icon-button-border-radius: 500px !default;
$icon-button-background: $global-muted-background !default;
$icon-button-color: $global-muted-color !default;
$icon-button-hover-background: darken($icon-button-background, 5%) !default;
$icon-button-hover-color: $global-color !default;
$icon-button-active-background: darken($icon-button-background, 10%) !default;
$icon-button-active-color: $global-color !default;
$inverse-icon-link-color: $inverse-global-muted-color !default;
$inverse-icon-link-hover-color: $inverse-global-color !default;
$inverse-icon-link-active-color: $inverse-global-color !default;
$inverse-icon-button-background: $inverse-global-muted-background !default;
$inverse-icon-button-color: $inverse-global-muted-color !default;
$inverse-icon-button-hover-background: darken($inverse-icon-button-background, 5%) !default;
$inverse-icon-button-hover-color: $inverse-global-color !default;
$inverse-icon-button-active-background: darken($inverse-icon-button-background, 10%) !default;
$inverse-icon-button-active-color: $inverse-global-color !default;
$iconnav-margin-horizontal: $global-small-margin !default;
$iconnav-margin-vertical: $iconnav-margin-horizontal !default;
$iconnav-item-color: $global-muted-color !default;
$iconnav-item-hover-color: $global-color !default;
$iconnav-item-active-color: $global-color !default;
$inverse-iconnav-item-color: $inverse-global-muted-color !default;
$inverse-iconnav-item-hover-color: $inverse-global-color !default;
$inverse-iconnav-item-active-color: $inverse-global-color !default;
$inverse-global-color-mode: light !default;
$label-padding-vertical: 0 !default;
$label-padding-horizontal: $global-small-margin !default;
$label-background: $global-primary-background !default;
$label-line-height: $global-line-height !default;
$label-font-size: $global-small-font-size !default;
$label-color: $global-inverse-color !default;
$label-success-background: $global-success-background !default;
$label-success-color: $global-inverse-color !default;
$label-warning-background: $global-warning-background !default;
$label-warning-color: $global-inverse-color !default;
$label-danger-background: $global-danger-background !default;
$label-danger-color: $global-inverse-color !default;
$inverse-label-background: $inverse-global-primary-background !default;
$inverse-label-color: $inverse-global-inverse-color !default;
$leader-fill-content: unquote('.') !default;
$leader-fill-margin-left: $global-small-gutter !default;
$lightbox-z-index: $global-z-index+10 !default;
$lightbox-background: #000 !default;
$lightbox-item-color: rgba(255, 255, 255, 0.7) !default;
$lightbox-item-max-width: 100vw !default;
$lightbox-item-max-height: 100vh !default;
$lightbox-toolbar-padding-vertical: 10px !default;
$lightbox-toolbar-padding-horizontal: 10px !default;
$lightbox-toolbar-background: rgba(0, 0, 0, 0.3) !default;
$lightbox-toolbar-color: rgba(255, 255, 255, 0.7) !default;
$lightbox-toolbar-icon-padding: 5px !default;
$lightbox-toolbar-icon-color: rgba(255, 255, 255, 0.7) !default;
$lightbox-toolbar-icon-hover-color: #fff !default;
$lightbox-button-size: 50px !default;
$lightbox-button-background: $lightbox-toolbar-background !default;
$lightbox-button-color: rgba(255, 255, 255, 0.7) !default;
$lightbox-button-hover-color: #fff !default;
$link-muted-color: $global-muted-color !default;
$link-muted-hover-color: $global-color !default;
$link-text-hover-color: $global-muted-color !default;
$link-heading-hover-color: $global-primary-background !default;
$link-heading-hover-text-decoration: none !default;
$inverse-link-muted-color: $inverse-global-muted-color !default;
$inverse-link-muted-hover-color: $inverse-global-color !default;
$inverse-link-text-hover-color: $inverse-global-muted-color !default;
$inverse-link-heading-hover-color: $inverse-global-primary-background !default;
$list-margin-top: $global-small-margin !default;
$list-nested-padding-left: $global-gutter !default;
$list-divider-margin-top: $global-small-margin !default;
$list-divider-border-width: $global-border-width !default;
$list-divider-border: $global-border !default;
$list-striped-padding-vertical: $global-small-margin !default;
$list-striped-padding-horizontal: $global-small-margin !default;
$list-striped-background: $global-muted-background !default;
$list-bullet-width: ($global-line-height * 1em) !default;
$list-bullet-height: $list-bullet-width !default;
$list-bullet-margin-right: $global-small-margin !default;
$list-bullet-icon-color: $global-color !default;
$list-large-margin-top: $global-margin !default;
$list-large-divider-margin-top: $global-margin !default;
$list-large-striped-padding-vertical: $global-margin !default;
$list-large-striped-padding-horizontal: $global-small-margin !default;
$internal-list-bullet-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22#000%22%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20%2F%3E%0A%3C%2Fsvg%3E" !default;
$inverse-list-divider-border: $inverse-global-border !default;
$inverse-list-striped-background: $inverse-global-muted-background !default;
$inverse-list-bullet-icon-color: $inverse-global-color !default;
$margin-margin: $global-margin !default;
$margin-small-margin: $global-small-margin !default;
$margin-medium-margin: $global-medium-margin !default;
$margin-large-margin: $global-medium-margin !default;
$margin-large-margin-l: $global-large-margin !default;
$margin-xlarge-margin: $global-large-margin !default;
$global-xlarge-margin: 140px !default;
$margin-xlarge-margin-l: $global-xlarge-margin !default;
$marker-padding: 5px !default;
$marker-background: $global-secondary-background !default;
$marker-color: $global-inverse-color !default;
$marker-hover-color: $global-inverse-color !default;
$inverse-marker-background: $global-muted-background !default;
$inverse-marker-color: $global-color !default;
$inverse-marker-hover-color: $global-color !default;
$modal-z-index: $global-z-index+10 !default;
$modal-background: rgba(0, 0, 0, 0.6) !default;
$modal-padding-horizontal: 15px !default;
$modal-padding-horizontal-s: $global-gutter !default;
$modal-padding-horizontal-m: $global-medium-gutter !default;
$modal-padding-vertical: $modal-padding-horizontal !default;
$modal-padding-vertical-s: 50px !default;
$modal-dialog-width: 600px !default;
$modal-dialog-background: $global-background !default;
$modal-container-width: 1200px !default;
$modal-body-padding-horizontal: $global-gutter !default;
$modal-body-padding-vertical: $global-gutter !default;
$modal-header-padding-horizontal: $global-gutter !default;
$modal-header-padding-vertical: ($modal-header-padding-horizontal / 2) !default;
$modal-header-background: $modal-dialog-background !default;
$modal-footer-padding-horizontal: $global-gutter !default;
$modal-footer-padding-vertical: ($modal-footer-padding-horizontal / 2) !default;
$modal-footer-background: $modal-dialog-background !default;
$modal-title-font-size: $global-xlarge-font-size !default;
$modal-title-line-height: 1.3 !default;
$modal-close-position: $global-small-margin !default;
$modal-close-padding: 5px !default;
$modal-close-outside-position: 0 !default;
$modal-close-outside-translate: 100% !default;
$modal-close-outside-color: lighten($global-inverse-color, 20%) !default;
$modal-close-outside-hover-color: $global-inverse-color !default;
$nav-item-padding-vertical: 5px !default;
$nav-item-padding-horizontal: 0 !default;
$nav-sublist-padding-vertical: 5px !default;
$nav-sublist-padding-left: 15px !default;
$nav-sublist-deeper-padding-left: 15px !default;
$nav-sublist-item-padding-vertical: 2px !default;
$nav-parent-icon-width: ($global-line-height * 1em) !default;
$nav-parent-icon-height: $nav-parent-icon-width !default;
$nav-parent-icon-color: $global-color !default;
$nav-header-padding-vertical: $nav-item-padding-vertical !default;
$nav-header-padding-horizontal: $nav-item-padding-horizontal !default;
$nav-header-font-size: $global-small-font-size !default;
$nav-header-text-transform: uppercase !default;
$nav-header-margin-top: $global-margin !default;
$nav-divider-margin-vertical: 5px !default;
$nav-divider-margin-horizontal: 0 !default;
$nav-default-item-color: $global-muted-color !default;
$nav-default-item-hover-color: $global-color !default;
$nav-default-item-active-color: $global-emphasis-color !default;
$nav-default-header-color: $global-emphasis-color !default;
$nav-default-divider-border-width: $global-border-width !default;
$nav-default-divider-border: $global-border !default;
$nav-default-sublist-item-color: $global-muted-color !default;
$nav-default-sublist-item-hover-color: $global-color !default;
$nav-default-sublist-item-active-color: $global-emphasis-color !default;
$nav-primary-item-font-size: $global-large-font-size !default;
$nav-primary-item-line-height: $global-line-height !default;
$nav-primary-item-color: $global-muted-color !default;
$nav-primary-item-hover-color: $global-color !default;
$nav-primary-item-active-color: $global-emphasis-color !default;
$nav-primary-header-color: $global-emphasis-color !default;
$nav-primary-divider-border-width: $global-border-width !default;
$nav-primary-divider-border: $global-border !default;
$nav-primary-sublist-item-color: $global-muted-color !default;
$nav-primary-sublist-item-hover-color: $global-color !default;
$nav-primary-sublist-item-active-color: $global-emphasis-color !default;
$internal-nav-parent-close-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22#000%22%20stroke-width%3D%221.1%22%20points%3D%2210%201%204%207%2010%2013%22%20%2F%3E%0A%3C%2Fsvg%3E" !default;
$internal-nav-parent-open-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22#000%22%20stroke-width%3D%221.1%22%20points%3D%221%204%207%2010%2013%204%22%20%2F%3E%0A%3C%2Fsvg%3E" !default;
$inverse-nav-parent-icon-color: $inverse-global-color !default;
$inverse-nav-default-item-color: $inverse-global-muted-color !default;
$inverse-nav-default-item-hover-color: $inverse-global-color !default;
$inverse-nav-default-item-active-color: $inverse-global-emphasis-color !default;
$inverse-nav-default-header-color: $inverse-global-emphasis-color !default;
$inverse-nav-default-divider-border: $inverse-global-border !default;
$inverse-nav-default-sublist-item-color: $inverse-global-muted-color !default;
$inverse-nav-default-sublist-item-hover-color: $inverse-global-color !default;
$inverse-nav-default-sublist-item-active-color: $inverse-global-emphasis-color !default;
$inverse-nav-primary-item-color: $inverse-global-muted-color !default;
$inverse-nav-primary-item-hover-color: $inverse-global-color !default;
$inverse-nav-primary-item-active-color: $inverse-global-emphasis-color !default;
$inverse-nav-primary-header-color: $inverse-global-emphasis-color !default;
$inverse-nav-primary-divider-border: $inverse-global-border !default;
$inverse-nav-primary-sublist-item-color: $inverse-global-muted-color !default;
$inverse-nav-primary-sublist-item-hover-color: $inverse-global-color !default;
$inverse-nav-primary-sublist-item-active-color: $inverse-global-emphasis-color !default;
$navbar-background: $global-muted-background !default;
$navbar-color-mode: none !default;
$navbar-nav-item-height: 80px !default;
$navbar-nav-item-padding-horizontal: 15px !default;
$navbar-nav-item-color: $global-muted-color !default;
$navbar-nav-item-font-size: $global-small-font-size !default;
$navbar-nav-item-font-family: $global-font-family !default;
$navbar-nav-item-hover-color: $global-color !default;
$navbar-nav-item-onclick-color: $global-emphasis-color !default;
$navbar-nav-item-active-color: $global-emphasis-color !default;
$navbar-item-color: $global-color !default;
$navbar-toggle-color: $global-muted-color !default;
$navbar-toggle-hover-color: $global-color !default;
$navbar-subtitle-font-size: $global-small-font-size !default;
$navbar-dropdown-z-index: $global-z-index+20 !default;
$navbar-dropdown-width: 200px !default;
$navbar-dropdown-margin: 15px !default;
$navbar-dropdown-padding: 25px !default;
$navbar-dropdown-background: $global-background !default;
$navbar-dropdown-color: $global-color !default;
$navbar-dropdown-grid-gutter-horizontal: ($navbar-dropdown-padding * 2) !default;
$navbar-dropdown-grid-gutter-vertical: $navbar-dropdown-grid-gutter-horizontal !default;
$navbar-dropdown-dropbar-margin-top: 0 !default;
$navbar-dropdown-dropbar-margin-bottom: $navbar-dropdown-dropbar-margin-top !default;
$navbar-dropdown-nav-item-color: $global-muted-color !default;
$navbar-dropdown-nav-item-hover-color: $global-color !default;
$navbar-dropdown-nav-item-active-color: $global-emphasis-color !default;
$navbar-dropdown-nav-header-color: $global-emphasis-color !default;
$navbar-dropdown-nav-divider-border-width: $global-border-width !default;
$navbar-dropdown-nav-divider-border: $global-border !default;
$navbar-dropdown-nav-sublist-item-color: $global-muted-color !default;
$navbar-dropdown-nav-sublist-item-hover-color: $global-color !default;
$navbar-dropdown-nav-sublist-item-active-color: $global-emphasis-color !default;
$navbar-dropbar-background: $navbar-dropdown-background !default;
$navbar-dropbar-z-index: $global-z-index - 20 !default;
$inverse-navbar-nav-item-color: $inverse-global-muted-color !default;
$inverse-navbar-nav-item-hover-color: $inverse-global-color !default;
$inverse-navbar-nav-item-onclick-color: $inverse-global-emphasis-color !default;
$inverse-navbar-nav-item-active-color: $inverse-global-emphasis-color !default;
$inverse-navbar-item-color: $inverse-global-color !default;
$inverse-navbar-toggle-color: $inverse-global-muted-color !default;
$inverse-navbar-toggle-hover-color: $inverse-global-color !default;
$notification-position: 10px !default;
$notification-z-index: $global-z-index+40 !default;
$notification-width: 350px !default;
$notification-message-margin-top: 10px !default;
$notification-message-padding: $global-small-gutter !default;
$notification-message-background: $global-muted-background !default;
$notification-message-color: $global-color !default;
$notification-message-font-size: $global-medium-font-size !default;
$notification-message-line-height: 1.4 !default;
$notification-close-top: $notification-message-padding+5px !default;
$notification-close-right: $notification-message-padding !default;
$notification-message-primary-color: $global-primary-background !default;
$notification-message-success-color: $global-success-background !default;
$notification-message-warning-color: $global-warning-background !default;
$notification-message-danger-color: $global-danger-background !default;
$offcanvas-z-index: $global-z-index !default;
$offcanvas-bar-width: 270px !default;
$offcanvas-bar-padding-vertical: $global-margin !default;
$offcanvas-bar-padding-horizontal: $global-margin !default;
$offcanvas-bar-background: $global-secondary-background !default;
$offcanvas-bar-color-mode: light !default;
$offcanvas-bar-width-m: 350px !default;
$offcanvas-bar-padding-vertical-m: $global-medium-gutter !default;
$offcanvas-bar-padding-horizontal-m: $global-medium-gutter !default;
$offcanvas-close-position: 20px !default;
$offcanvas-close-padding: 5px !default;
$offcanvas-overlay-background: rgba(0, 0, 0, 0.1) !default;
$overlay-padding-horizontal: $global-gutter !default;
$overlay-padding-vertical: $global-gutter !default;
$overlay-default-background: rgba($global-background, 0.8) !default;
$overlay-primary-background: rgba($global-secondary-background, 0.8) !default;
$overlay-primary-color-mode: light !default;
$padding-padding: $global-gutter !default;
$padding-padding-l: $global-medium-gutter !default;
$padding-small-padding: $global-small-gutter !default;
$padding-large-padding: $global-gutter !default;
$padding-large-padding-l: $global-large-gutter !default;
$pagination-margin-horizontal: 20px !default;
$pagination-item-color: $global-muted-color !default;
$pagination-item-hover-color: $global-color !default;
$pagination-item-hover-text-decoration: none !default;
$pagination-item-active-color: $global-color !default;
$pagination-item-disabled-color: $global-muted-color !default;
$inverse-pagination-item-color: $inverse-global-muted-color !default;
$inverse-pagination-item-hover-color: $inverse-global-color !default;
$inverse-pagination-item-active-color: $inverse-global-color !default;
$inverse-pagination-item-disabled-color: $inverse-global-muted-color !default;
$placeholder-margin-vertical: $global-margin !default;
$placeholder-padding-vertical: $global-gutter !default;
$placeholder-padding-horizontal: $global-gutter !default;
$placeholder-background: transparent !default;
$position-small-margin: $global-small-gutter !default;
$position-medium-margin: $global-gutter !default;
$position-large-margin: $global-gutter !default;
$position-large-margin-l: 50px !default;
$progress-height: 15px !default;
$progress-margin-vertical: $global-margin !default;
$progress-background: $global-muted-background !default;
$progress-bar-background: $global-primary-background !default;
$search-color: $global-color !default;
$search-placeholder-color: $global-muted-color !default;
$search-icon-color: $global-muted-color !default;
$search-default-width: 180px !default;
$search-default-height: $global-control-height !default;
$search-default-padding-horizontal: 6px !default;
$search-default-background: transparent !default;
$search-default-focus-background: $search-default-background !default;
$search-default-icon-width: $global-control-height !default;
$search-navbar-width: 400px !default;
$search-navbar-height: 40px !default;
$search-navbar-background: transparent !default;
$search-navbar-font-size: $global-large-font-size !default;
$search-navbar-icon-width: 40px !default;
$search-large-width: 500px !default;
$search-large-height: 80px !default;
$search-large-background: transparent !default;
$search-large-font-size: $global-xxlarge-font-size !default;
$search-large-icon-width: 80px !default;
$search-toggle-color: $global-muted-color !default;
$search-toggle-hover-color: $global-color !default;
$inverse-search-color: $inverse-global-color !default;
$inverse-search-placeholder-color: $inverse-global-muted-color !default;
$inverse-search-icon-color: $inverse-global-muted-color !default;
$inverse-search-default-background: transparent !default;
$inverse-search-default-focus-background: $inverse-search-default-background !default;
$inverse-search-navbar-background: transparent !default;
$inverse-search-large-background: transparent !default;
$inverse-search-toggle-color: $inverse-global-muted-color !default;
$inverse-search-toggle-hover-color: $inverse-global-color !default;
$section-padding-vertical: $global-medium-margin !default;
$section-padding-vertical-m: $global-large-margin !default;
$section-xsmall-padding-vertical: $global-margin !default;
$section-small-padding-vertical: $global-medium-margin !default;
$section-large-padding-vertical: $global-large-margin !default;
$section-large-padding-vertical-m: $global-xlarge-margin !default;
$section-xlarge-padding-vertical: $global-xlarge-margin !default;
$section-xlarge-padding-vertical-m: ($global-large-margin + $global-xlarge-margin) !default;
$section-default-background: $global-background !default;
$section-muted-background: $global-muted-background !default;
$section-primary-background: $global-primary-background !default;
$section-primary-color-mode: light !default;
$section-secondary-background: $global-secondary-background !default;
$section-secondary-color-mode: light !default;
$slidenav-padding-vertical: 5px !default;
$slidenav-padding-horizontal: 10px !default;
$slidenav-color: rgba($global-color, 0.5) !default;
$slidenav-hover-color: rgba($global-color, 0.9) !default;
$slidenav-active-color: rgba($global-color, 0.5) !default;
$slidenav-large-padding-vertical: 10px !default;
$slidenav-large-padding-horizontal: $slidenav-large-padding-vertical !default;
$inverse-slidenav-color: rgba($inverse-global-color, 0.7) !default;
$inverse-slidenav-hover-color: rgba($inverse-global-color, 0.95) !default;
$inverse-slidenav-active-color: rgba($inverse-global-color, 0.7) !default;
$sortable-dragged-z-index: $global-z-index+50 !default;
$sortable-placeholder-opacity: 0 !default;
$sortable-empty-height: 50px !default;
$spinner-size: 30px !default;
$spinner-stroke-width: 1 !default;
$spinner-radius: floor(($spinner-size - $spinner-stroke-width) / 2) !default;
$spinner-circumference: round(2 * 3.141 * $spinner-radius) !default;
$spinner-duration: 1.4s !default;
$sticky-z-index: $global-z-index - 20 !default;
$sticky-animation-duration: 0.2s !default;
$sticky-reverse-animation-duration: 0.2s !default;
$subnav-margin-horizontal: 20px !default;
$subnav-item-color: $global-muted-color !default;
$subnav-item-hover-color: $global-color !default;
$subnav-item-hover-text-decoration: none !default;
$subnav-item-active-color: $global-emphasis-color !default;
$subnav-divider-margin-horizontal: $subnav-margin-horizontal !default;
$subnav-divider-border-height: 1.5em !default;
$subnav-divider-border-width: $global-border-width !default;
$subnav-divider-border: $global-border !default;
$subnav-pill-item-padding-vertical: 5px !default;
$subnav-pill-item-padding-horizontal: 10px !default;
$subnav-pill-item-background: transparent !default;
$subnav-pill-item-color: $subnav-item-color !default;
$subnav-pill-item-hover-background: $global-muted-background !default;
$subnav-pill-item-hover-color: $global-color !default;
$subnav-pill-item-onclick-background: $subnav-pill-item-hover-background !default;
$subnav-pill-item-onclick-color: $subnav-pill-item-hover-color !default;
$subnav-pill-item-active-background: $global-primary-background !default;
$subnav-pill-item-active-color: $global-inverse-color !default;
$subnav-item-disabled-color: $global-muted-color !default;
$inverse-subnav-item-color: $inverse-global-muted-color !default;
$inverse-subnav-item-hover-color: $inverse-global-color !default;
$inverse-subnav-item-active-color: $inverse-global-emphasis-color !default;
$inverse-subnav-divider-border: $inverse-global-border !default;
$inverse-subnav-pill-item-background: transparent !default;
$inverse-subnav-pill-item-color: $inverse-global-muted-color !default;
$inverse-subnav-pill-item-hover-background: $inverse-global-muted-background !default;
$inverse-subnav-pill-item-hover-color: $inverse-global-color !default;
$inverse-subnav-pill-item-onclick-background: $inverse-subnav-pill-item-hover-background !default;
$inverse-subnav-pill-item-onclick-color: $inverse-subnav-pill-item-hover-color !default;
$inverse-subnav-pill-item-active-background: $inverse-global-primary-background !default;
$inverse-subnav-pill-item-active-color: $inverse-global-inverse-color !default;
$inverse-subnav-item-disabled-color: $inverse-global-muted-color !default;
$tab-margin-horizontal: 20px !default;
$tab-item-padding-horizontal: 10px !default;
$tab-item-padding-vertical: 5px !default;
$tab-item-color: $global-muted-color !default;
$tab-item-hover-color: $global-color !default;
$tab-item-hover-text-decoration: none !default;
$tab-item-active-color: $global-emphasis-color !default;
$tab-item-disabled-color: $global-muted-color !default;
$inverse-tab-item-color: $inverse-global-muted-color !default;
$inverse-tab-item-hover-color: $inverse-global-color !default;
$inverse-tab-item-active-color: $inverse-global-emphasis-color !default;
$inverse-tab-item-disabled-color: $inverse-global-muted-color !default;
$table-margin-vertical: $global-margin !default;
$table-cell-padding-vertical: 16px !default;
$table-cell-padding-horizontal: 12px !default;
$table-header-cell-font-size: $global-small-font-size !default;
$table-header-cell-font-weight: normal !default;
$table-header-cell-color: $global-muted-color !default;
$table-footer-font-size: $global-small-font-size !default;
$table-caption-font-size: $global-small-font-size !default;
$table-caption-color: $global-muted-color !default;
$table-row-active-background: #ffd !default;
$table-divider-border-width: $global-border-width !default;
$table-divider-border: $global-border !default;
$table-striped-row-background: $global-muted-background !default;
$table-hover-row-background: $table-row-active-background !default;
$table-small-cell-padding-vertical: 10px !default;
$table-small-cell-padding-horizontal: 12px !default;
$table-large-cell-padding-vertical: 22px !default;
$table-large-cell-padding-horizontal: 12px !default;
$table-expand-min-width: 150px !default;
$inverse-table-header-cell-color: $inverse-global-color !default;
$inverse-table-caption-color: $inverse-global-muted-color !default;
$inverse-table-row-active-background: fade-out($inverse-global-muted-background, 0.02) !default;
$inverse-table-divider-border: $inverse-global-border !default;
$inverse-table-striped-row-background: $inverse-global-muted-background !default;
$inverse-table-hover-row-background: $inverse-table-row-active-background !default;
$text-lead-font-size: $global-large-font-size !default;
$text-lead-line-height: 1.5 !default;
$text-lead-color: $global-emphasis-color !default;
$text-meta-font-size: $global-small-font-size !default;
$text-meta-line-height: 1.4 !default;
$text-meta-color: $global-muted-color !default;
$text-small-font-size: $global-small-font-size !default;
$text-small-line-height: 1.5 !default;
$text-large-font-size: $global-large-font-size !default;
$text-large-line-height: 1.5 !default;
$text-bold-font-weight: bolder !default;
$text-muted-color: $global-muted-color !default;
$text-emphasis-color: $global-emphasis-color !default;
$text-primary-color: $global-primary-background !default;
$text-secondary-color: $global-secondary-background !default;
$text-success-color: $global-success-background !default;
$text-warning-color: $global-warning-background !default;
$text-danger-color: $global-danger-background !default;
$text-background-color: $global-primary-background !default;
$inverse-text-lead-color: $inverse-global-color !default;
$inverse-text-meta-color: $inverse-global-muted-color !default;
$inverse-text-muted-color: $inverse-global-muted-color !default;
$inverse-text-emphasis-color: $inverse-global-emphasis-color !default;
$inverse-text-primary-color: $inverse-global-primary-background !default;
$inverse-text-secondary-color: $inverse-global-primary-background !default;
$thumbnav-margin-horizontal: 15px !default;
$thumbnav-margin-vertical: $thumbnav-margin-horizontal !default;
$tile-padding-horizontal: 15px !default;
$tile-padding-horizontal-s: $global-gutter !default;
$tile-padding-horizontal-m: $global-medium-gutter !default;
$tile-padding-vertical: $global-medium-margin !default;
$tile-padding-vertical-m: $global-large-margin !default;
$tile-xsmall-padding-vertical: $global-margin !default;
$tile-small-padding-vertical: $global-medium-margin !default;
$tile-large-padding-vertical: $global-large-margin !default;
$tile-large-padding-vertical-m: $global-xlarge-margin !default;
$tile-xlarge-padding-vertical: $global-xlarge-margin !default;
$tile-xlarge-padding-vertical-m: ($global-large-margin + $global-xlarge-margin) !default;
$tile-default-background: $global-background !default;
$tile-muted-background: $global-muted-background !default;
$tile-primary-background: $global-primary-background !default;
$tile-primary-color-mode: light !default;
$tile-secondary-background: $global-secondary-background !default;
$tile-secondary-color-mode: light !default;
$tooltip-z-index: $global-z-index+30 !default;
$tooltip-max-width: 200px !default;
$tooltip-padding-vertical: 3px !default;
$tooltip-padding-horizontal: 6px !default;
$tooltip-background: #666 !default;
$tooltip-border-radius: 2px !default;
$tooltip-color: $global-inverse-color !default;
$tooltip-font-size: 12px !default;
$tooltip-margin: 10px !default;
$totop-padding: 5px !default;
$totop-color: $global-muted-color !default;
$totop-hover-color: $global-color !default;
$totop-active-color: $global-emphasis-color !default;
$inverse-totop-color: $inverse-global-muted-color !default;
$inverse-totop-hover-color: $inverse-global-color !default;
$inverse-totop-active-color: $inverse-global-emphasis-color !default;
$transition-duration: 0.3s !default;
$transition-scale: 1.1 !default;
$transition-slide-small-translate: 10px !default;
$transition-slide-medium-translate: 50px !default;
$transition-slow-duration: 0.7s !default;
$panel-scrollable-height: 170px !default;
$panel-scrollable-padding: 10px !default;
$panel-scrollable-border-width: $global-border-width !default;
$panel-scrollable-border: $global-border !default;
$border-rounded-border-radius: 5px !default;
$box-shadow-duration: 0.1s !default;
$box-shadow-bottom-height: 30px !default;
$box-shadow-bottom-border-radius: 100% !default;
$box-shadow-bottom-background: #444 !default;
$box-shadow-bottom-blur: 20px !default;
$dropcap-margin-right: 10px !default;
$dropcap-font-size: (($global-line-height * 3) * 1em) !default;
$logo-font-size: $global-large-font-size !default;
$logo-font-family: $global-font-family !default;
$logo-color: $global-color !default;
$logo-hover-color: $global-color !default;
$dragover-box-shadow: 0 0 20px rgba(100, 100, 100, 0.3) !default;
$inverse-logo-color: $inverse-global-color !default;
$inverse-logo-hover-color: $inverse-global-color !default;
$deprecated: false !default;
$breakpoint-small: 640px !default;
$breakpoint-medium: 960px !default;
$breakpoint-large: 1200px !default;
$breakpoint-xlarge: 1600px !default;
$breakpoint-xsmall-max: ($breakpoint-small - 1) !default;
$breakpoint-small-max: ($breakpoint-medium - 1) !default;
$breakpoint-medium-max: ($breakpoint-large - 1) !default;
$breakpoint-large-max: ($breakpoint-xlarge - 1) !default;
$global-small-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08) !default;
$global-medium-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08) !default;
$global-large-box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16) !default;
$global-xlarge-box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16) !default;
$width-small-width: 150px !default;
$width-medium-width: 300px !default;
$width-large-width: 450px !default;
$width-xlarge-width: 600px !default;
$width-xxlarge-width: 750px !default;
$accordion-icon-margin-left: 10px !default;
$accordion-icon-color: $global-color !default;
$internal-accordion-open-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22#000%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%3C%2Fsvg%3E" !default;
$internal-accordion-close-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22#000%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%20%20%20%20%3Crect%20fill%3D%22#000%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%0A%3C%2Fsvg%3E" !default;
$alert-close-opacity: 0.4 !default;
$alert-close-hover-opacity: 0.8 !default;
$article-meta-link-color: $article-meta-color !default;
$article-meta-link-hover-color: $global-color !default;
$base-code-padding-horizontal: 6px !default;
$base-code-padding-vertical: 2px !default;
$base-code-background: $global-muted-background !default;
$base-blockquote-color: $global-emphasis-color !default;
$base-blockquote-footer-color: $global-color !default;
$base-pre-padding: 10px !default;
$base-pre-background: $global-background !default;
$base-pre-border-width: $global-border-width !default;
$base-pre-border: $global-border !default;
$base-pre-border-radius: 3px !default;
$inverse-base-blockquote-color: $inverse-global-emphasis-color !default;
$inverse-base-blockquote-footer-color: $inverse-global-color !default;
$button-text-transform: uppercase !default;
$button-default-border: $global-border !default;
$button-default-hover-border: darken($global-border, 20%) !default;
$button-default-active-border: darken($global-border, 30%) !default;
$button-disabled-border: $global-border !default;
$button-text-border-width: $global-border-width !default;
$button-text-border: $button-text-hover-color !default;
$card-hover-box-shadow: $global-large-box-shadow !default;
$card-default-box-shadow: $global-medium-box-shadow !default;
$card-default-hover-box-shadow: $global-large-box-shadow !default;
$card-default-header-border-width: $global-border-width !default;
$card-default-header-border: $global-border !default;
$card-default-footer-border-width: $global-border-width !default;
$card-default-footer-border: $global-border !default;
$card-primary-box-shadow: $global-medium-box-shadow !default;
$card-primary-hover-box-shadow: $global-large-box-shadow !default;
$card-secondary-box-shadow: $global-medium-box-shadow !default;
$card-secondary-hover-box-shadow: $global-large-box-shadow !default;
$comment-primary-padding: $global-gutter !default;
$comment-primary-background: $global-muted-background !default;
$description-list-term-font-size: $global-small-font-size !default;
$description-list-term-font-weight: normal !default;
$description-list-term-text-transform: uppercase !default;
$dotnav-item-border-width: 1px !default;
$dotnav-item-border: rgba($global-color, 0.4) !default;
$dotnav-item-hover-border: transparent !default;
$dotnav-item-onclick-border: transparent !default;
$dotnav-item-active-border: transparent !default;
$dropdown-nav-font-size: $global-small-font-size !default;
$dropdown-box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15) !default;
$form-range-thumb-border-width: $global-border-width !default;
$form-range-thumb-border: darken($global-border, 10%) !default;
$form-range-track-border-radius: 500px !default;
$form-border: $global-border !default;
$form-focus-border: $global-primary-background !default;
$form-disabled-border: $global-border !default;
$form-danger-border: $global-danger-background !default;
$form-success-border: $global-success-background !default;
$form-blank-focus-border: $global-border !default;
$form-blank-focus-border-style: dashed !default;
$form-radio-border-width: $global-border-width !default;
$form-radio-border: darken($global-border, 10%) !default;
$form-radio-focus-border: $global-primary-background !default;
$form-radio-checked-border: transparent !default;
$form-radio-disabled-border: $global-border !default;
$form-label-color: $global-emphasis-color !default;
$form-label-font-size: $global-small-font-size !default;
$inverse-form-label-color: $inverse-global-emphasis-color !default;
$label-border-radius: 2px !default;
$label-text-transform: uppercase !default;
$list-striped-border-width: $global-border-width !default;
$list-striped-border: $global-border !default;
$modal-header-border-width: $global-border-width !default;
$modal-header-border: $global-border !default;
$modal-footer-border-width: $global-border-width !default;
$modal-footer-border: $global-border !default;
$modal-close-full-padding: $global-margin !default;
$modal-close-full-background: $modal-dialog-background !default;
$nav-default-font-size: $global-small-font-size !default;
$navbar-nav-item-text-transform: uppercase !default;
$navbar-dropdown-nav-font-size: $global-small-font-size !default;
$navbar-dropdown-box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15) !default;
$navbar-dropbar-box-shadow: 0 5px 7px rgba(0, 0, 0, 0.05) !default;
$navbar-dropdown-grid-divider-border-width: $global-border-width !default;
$navbar-dropdown-grid-divider-border: $navbar-dropdown-nav-divider-border !default;
$placeholder-border-width: $global-border-width !default;
$placeholder-border: $global-border !default;
$progress-border-radius: 500px !default;
$search-default-border-width: $global-border-width !default;
$search-default-border: $global-border !default;
$subnav-item-font-size: $global-small-font-size !default;
$subnav-item-text-transform: uppercase !default;
$tab-border-width: $global-border-width !default;
$tab-border: $global-border !default;
$tab-item-border-width: $global-border-width !default;
$tab-item-font-size: $global-small-font-size !default;
$tab-item-text-transform: uppercase !default;
$tab-item-active-border: $global-primary-background !default;
$inverse-tab-border: $inverse-global-border !default;
$table-striped-border-width: $global-border-width !default;
$table-striped-border: $global-border !default;
$text-meta-link-color: $text-meta-color !default;
$text-meta-link-hover-color: $global-color !default;
$thumbnav-item-background: rgba($global-background, 0.4) !default;
$thumbnav-item-hover-background: transparent !default;
$thumbnav-item-active-background: transparent !default;