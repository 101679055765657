.vi-tri-1
	padding-bottom: 20px

.list-location
	margin-top: 40px
	.item
		.img
			width: 240px
			height: 240px
			border-radius: 50%
			margin: 0 auto
			overflow: hidden
			margin-bottom: -120px
			border: 5px solid #fff
			img
				width: 100%
				height: 100%
				object-fit: cover
		.caption
			padding-top: 150px
			padding-bottom: 30px
			text-align: center
			background-image: url(../img/vi-tri/bg.png)
			background-repeat: no-repeat
			background-size: cover
			border-radius: 10px
			.number
				font-size: r(36px)
				font-weight: 900
				color: $mainColor
				font-family: $font-1
				text-transform: uppercase
				display: block
				margin-bottom: 5px
			.text
				font-size: 13px
				line-height: 1.4
	@media screen and ( max-width: 1200.98px )
		.item
			.img
				width: 160px
				height: 160px
				margin-bottom: -60px
			.caption
				padding-top: 80px
				padding-right: 20px
				padding-left: 20px
	@media screen and ( max-width: 768.98px )
		.item
			.img
				width: 120px
				height: 120px
