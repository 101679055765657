.contact-1
	.uk-container
		> .uk-grid
			box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1)
			border-radius: 20px
			overflow: hidden
	.contact-info
		padding: 40px 30px
		background-image: url(../img/contact/bg-1.png)
		background-size: cover
		background-repeat: no-repeat
		color: #fff
		.section-lead
			color: #fff
			margin-bottom: 40px
		ul
			list-style: none
			padding-left: 0
			margin-bottom: 0
			li
				display: flex
				+ li
					margin-top: 20px
				em
					margin-right: 10px
					font-size: r(30px)
				p
					margin: 0
					line-height: 1.4
					> *
						display: block
					strong
						font-size: r(18px)
						margin-bottom: 5px
	.contact-form
		padding: 40px 50px
		background: #fff
		.wrap-form
			position: relative
			.uk-grid
				margin-left: -30px
				> *
					padding-left: 30px
				> .uk-grid-margin
					margin-top: 30px
			.form-group
				input,textarea,select
					background: #fafafa
					border: 1px solid #e1e1e1
			.frm-btn
				margin-top: 30px
				.frm-btn-submit
					width: 120px
					background: $mainColor
					&:hover
						background: darken($mainColor, 20%)
		.frm-btnwrap
			position: absolute
			right: 0
			bottom: 26px
	.frm-captcha
		display: flex
		flex-direction: row-reverse
		justify-content: flex-end
		margin-top: 20px
		.frm-captcha-input
			width: calc(63% - 210px)
			margin-left: 0
			label
				display: none
	.RadCaptcha
		display: flex
		flex-direction: column-reverse
		width: 37% !important
	#ctl00_mainContent_ctl01_captcha_ctl01
		display: flex
		align-items: center
	.rcRefreshImage
		font-size: 0
		line-height: 1
		margin-left: 16px
		&:before
			content: '\f450'
			font-family: Material Design Icons
			font-size: 40px
			color: #666
	@media (max-width: 768.98px)
		.frm-btnwrap
			position: static !important
			margin-top: 20px
		.frm-captcha
			.frm-captcha-input
				width: 50%
		.RadCaptcha
			width: 50% !important
		.contact-info-wrapper
			padding: 30px
			img
				max-width: 150px
	@media (max-width: 576px)
		.RadCaptcha
			width: 100% !important
		.frm-captcha
			flex-direction: column-reverse
			.frm-captcha-input
				width: 100%

	@media screen and ( max-width: 768.98px )
		.contact-form
			padding: 30px

.contact-2
	.map
		border-radius: 20px
		overflow: hidden
		iframe
			width: 100%
			height: 600px
	@media screen and ( max-width: 768.98px )
		.map
			iframe
				height: 300px