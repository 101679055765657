.home-1
	min-height: 100vh
	background-repeat: no-repeat
	background-size: cover
	background-position: center center

	.list-items
		display: flex
		justify-content: center
		margin-left: -30px
		margin-bottom: -30px
		margin-top: 40px
		flex-wrap: wrap
		> *
			width: 1/6 * 100%
			padding-left: 30px
			margin-bottom: 30px
		.item
			display: flex
			align-items: center
			justify-content: center
			flex-direction: column
			width: 190px
			height: 190px
			background: $gradient-2
			border-radius: 50%
			color: #fff
			border: 2px solid #fff
			padding: 20px 30px
			text-align: center
			margin: 0 auto
			.number,.unit
				font-family: $font-1
				font-weight: 900
				font-size: r(40px)
				line-height: 1.2
			.unit
				font-size: r(18px)
			.text
				font-weight: 500
				margin-top: 10px
				line-height: 1.2
	@media screen and ( max-width: 1280.98px )
		.list-items
			> *
				width: 33.333%
	@media screen and ( max-width: 768.98px )
		min-height: auto
		.list-items
			> *
				width: auto
	@media screen and ( max-width: 576px )
		.list-items
			.item
				width: 130px
				height: 130px
				padding: 10px 20px
				.number
					font-size: 20px
				.unit
					font-size: 14px
				.text
					font-size: 12px
					margin-top: 5px
