.about-1
	position: relative
	.subnav-wrapper
		position: absolute
		top: 0
		left: 50%
		transform: translateX(-50%)
		max-width: 100%
		.uk-container
			padding: 0
	.list-items
		justify-content: flex-start !important
	.btn-primary
		display: none
	@media screen and (max-width: 1600.98px)
		padding: 100px 0
	@media screen and (max-width: 1280.98px)
		.list-items
			> *
				width: 25% !important
	@media screen and (max-width: 1024.98px)
		.list-items
			> *
				width: 50% !important