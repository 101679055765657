.home-4
	background-image: url(../img/home/bg-4.jpg)
	background-repeat: no-repeat
	background-size: cover
	background-position: center center
	color: #fff
	.section-title,.section-lead
		color: #fff
	.section-lead
		margin-bottom: 30px
	.item
		.icon
			display: flex
			align-items: center
			justify-content: center
			width: 140px
			height: 140px
			background-color: rgba(#000 , 0.2)
			border: 1px solid rgba(#f58220 , 0.2)
			border-radius: 50%
			margin: 0 auto 10px
			img
				max-width: 50px
		.text
			line-height: 1.4
			text-align: center
	@media screen and ( max-width: 576px )
		.item
			.icon
				width: 120px
				height: 120px
				img
					max-width: 50px
