$global-font-family: 'Roboto', sans-serif !important;

$breakpoint-small: 576px !default;
$breakpoint-medium: 768.98px !default;
$breakpoint-large: 1024.98px !default;
$breakpoint-xlarge: 1200.98px !default;

$container-max-width: 100% !default;
$container-padding-horizontal: 15px !default;
$container-padding-horizontal-s: 15px !default;
$container-padding-horizontal-m: 15px !default;

$grid-gutter-horizontal-l: 30px;
$global-color: #333;

$section-xlarge-padding-vertical: 40px;
$section-xlarge-padding-vertical-m: 190px;

@import "src/_plugins/uikit/scss/variables-theme.scss";
@import "src/_plugins/uikit/scss/mixins-theme.scss";

@mixin hook-container-misc() {
	@media (min-width: $breakpoint-small) {
		.uk-container {
			max-width: 100%; } }
	@media (min-width: $breakpoint-medium) {
		.uk-container {
			max-width: 740px; } }
	@media (min-width: $breakpoint-large) {
		.uk-container {
			max-width: 990px; } }
	@media (min-width: $breakpoint-xlarge) {
		.uk-container {
			max-width: 1410px; } } }


@import "src/_plugins/uikit/scss/uikit-theme.scss";
