.thu-vien
	padding-bottom: 40px
	.image
		width: 100%
		overflow: hidden
		border-radius: 20px
		&:after
			content: url(../img/search-icon.png)
			position: absolute
			top: 50%
			left: 50%
			transform: translate(-50%,-50%)
			z-index: 3
			transition: 0.3s all
			opacity: 0
		&:hover
			&::after
				opacity: 1
	.item
		&.item-rect
			.image
				+img-scale(260 / 690 * 100%)
		&.item-square
			.image
				+img-scale(260 / 330 * 100%)
	@media (max-width: 1024.98px)
		.image
			&::after
				opacity: 1
	@media (max-width: 768.98px)
		.item
			&.item-rect
				.image
					+img-scale(260 / 330 * 100%)