.wrap-form {
	.uk-grid {
		margin-left: -10px;
		> * {
			padding-left: 10px; }
		> .uk-grid-margin {
			margin-top: 10px; } }
	.form-group {
		input,textarea,select {
			width: 100%;
			height: 40px;
			border-radius: 4px;
			padding: 0 15px;
			font-size: 14px;
			color: #666666;
			border: 0; }
		textarea {
			height: 80px;
			padding-top: 10px;
			padding-bottom: 10px; } }
	.frm-btnwrap {
		label {
			display: none; } }
	.frm-btn {
		text-align: right;
		margin-top: 10px;
		.frm-btn-submit {
			height: 40px;
			width: 80px;
			border-radius: 20px;
			background: $blue-1;
			color: #fff;
			text-transform: uppercase;
			font-size: 14px;
			letter-spacing: 0.1rem;
			border: 0;
			font-weight: 700;
			cursor: pointer;
			transition: 0.3s all;
			&:hover {
				background: darken($blue-1, 20%); } } }
	.frm-btn-reset {
		display: none; }
	.frm-captcha {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
		margin-top: 10px;
		.frm-captcha-input {
			width: auto;
			margin-left: auto;
			label {
				display: none; } } }
	.RadCaptcha {
		display: flex;
		flex-direction: column-reverse; }
	#ctl00_mdl208_ctl00_captcha_ctl01,#ctl00_mdl144_ctl00_captcha_ctl01,#ctl00_mdl213_ctl00_captcha_ctl01 {
		display: flex;
		align-items: center;
		img {
			height: 40px !important; } }
	.rcRefreshImage {
		font-size: 0;
		line-height: 1;
		margin-left: 10px;
		&:before {
			content: '\f450';
			font-family: Material Design Icons;
			font-size: 40px;
			color: #fff; } }
	@media (max-width: 576px) {
		.RadCaptcha {
			width: 100% !important; }
		.frm-captcha {
			flex-direction: column-reverse;
			.frm-captcha-input {
				width: 100%; } } } }

.section-title {
	font-size: 20px;
	color: $blue-1;
	text-transform: uppercase;
	line-height: 1.2;
	font-weight: 500;
	letter-spacing: 0.1rem;
	margin-bottom: 10px;
	@media screen and ( max-width: 768.98px ) {
		font-size: 16px; } }

.section-lead {
	font-size: r(48px);
	color: $mainColor;
	font-family: $font-1 !important;
	font-weight: 700;
	line-height: 1.2;
	margin-bottom: r(20px);
	margin-top: 0;
	@media screen and ( max-width: 768.98px ) {
		font-size: 24px;
		line-height: 1.4; } }
.section-desc {
	font-size: r(18px);
	line-height: 1.3;
	color: #111;
	margin-bottom: 20px; }

.btn-primary {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	min-width: 120px;
	border-radius: 20px;
	border: 1px solid $mainColor;
	color: $mainColor;
	text-transform: uppercase;
	font-size: 14px;
	letter-spacing: 0.1rem;
	font-weight: 700;
	padding: 0 10px;
	transition: 0.3s all;
	&:hover {
		background: $mainColor;
		color: #fff; } }

.btn-gradient {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	min-width: 120px;
	border-radius: 20px;
	background-image: $gradient-1;
	color: #fff !important;
	text-transform: uppercase;
	font-size: 14px;
	letter-spacing: 0.1rem;
	font-weight: 700;
	padding: 0 10px;
	transition: 0.3s all; }


.slider-nav {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	font-size: 30px;
	border: 1px solid $mainColor;
	color: $mainColor;
	background: rgba(#fff , 0.75);
	z-index: 5;
	@media screen and ( max-width: 1600.98px ) {
		&.uk-position-center-left-out {
			right: auto;
			left: 0; }
		&.uk-position-center-right-out {
			left: auto;
			right: 0; } }
	@media screen and ( max-width: 576px ) {
		width: 36px;
		height: 36px;
		font-size: 18px; } }

.qtip {
	background: transparent;
	border: 0;
	.qtip-tip {
		canvas {
			display: none; } } }

.map-tooltip {
	border-radius: 20px;
	border: 3px solid #fff;
	overflow: hidden;
	.title {
		background: $mainColor;
		padding: 15px;
		color: #fff;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 700;
		line-height: 1.5;
		text-align: center; }
	.img {
		width: 280px;
		@include img-scale(56.25%); } }
