.home-banner
	padding-top: 0 !important
	.uk-slideshow-items
		min-height: 100vh
	.banner
		display: block
		color: #fff !important
		height: 100%
		img
			width: 100%
			height: 100%
			object-fit: cover
		.caption
			position: absolute
			font-family: $font-1 !important
			left: 50%
			transform: translateX(-50%)
			bottom: 150px
			font-weight: 300
			font-size: r(60px)
			text-transform: uppercase
			text-align: center
			line-height: 1.2
			h1
				font-family: $font-1 !important
				color: #fff
	.uk-slidenav
		color: #fff !important
		font-size: 30px
		padding: 0
	.mouse-scroll
		left: 50%
		transform: translateX(-50%)
		bottom: 100px
	@media screen and ( max-width: 768.98px )
		.banner
			.caption
				font-size: 28px
				bottom: 80px
		.mouse-scroll
			bottom: 30px