body, html {
	font-size: 13px;
	line-height: 1;
	overflow: auto;
	height: auto;
	width: auto;
	@media (min-width: 768px) {
		font-size: 14px; }
	@media (min-width: 1025px) {
		font-size: 15px; }
	@media (min-width: 1200px) {
		font-size: 16px; } }

*,*:before,*:after {
	outline: none;
	box-sizing: border-box; }

em,i {
	color: inherit; }

h1,h2,h3,h4,h5,h6 {
	color: inherit; }

body.homepage {
	@media screen and ( min-width: 1024px ) {
		overflow: hidden;
		height: 100%;
		width: 100%;
		footer,main {
			height: 100vh; } }
	section[class*='home-'] {
		@media screen and ( min-width: 1024px ) {
			padding: 0;
			display: flex;
			align-items: center;
			> * {
				width: 100%; } }
		@media screen and ( min-width: 1600px ) {
			padding-top: 120px;
			align-items: flex-start; } } }

body.contact-page {
	background: #f8f8f8; }

a {
	color: inherit;
	text-decoration: none;
	&:hover {
		text-decoration: none;
		color: inherit;
		* {
			text-decoration: none; } } }

input,textarea,select {
	font-family: inherit; }


.uk-slidenav {
	svg {
		display: none; } }

table {
	border-collapse: collapse; }

.onepage-pagination {
	display: none; }

.Module-202,.news-detail-page {
	.pages {
		display: none; } }

.fa-exclamation-triangle {
	font-size: 14px;
	color: red;
	display: inline-block;
	line-height: 2; }
