.mat-bang-4
	.other-page
		width: 330px
		margin-left: auto
		position: relative
		margin-bottom: 40px
		.current
			display: inline-flex
			align-items: center
			justify-content: space-between
			padding: 0 15px
			width: 100%
			height: 45px
			border: 1px solid #e1e1e1
			background: #f3f3f3
		.uk-dropdown
			width: 100%
			ul
				li
					+ li
						margin-top: 10px
						padding-top: 10px
						border-top: 1px solid #e1e1e1

	.plan-detail
		.thumb
			margin-bottom: 60px
		.info
			overflow: hidden
			border-radius: 5px
			background-image: $gradient-1
			padding: 1.5px
			.title
				height: 50px
				width: 100%
				display: flex
				align-items: center
				justify-content: center
				color: #fff
				background-image: $gradient-1
				text-transform: uppercase
				font-weight: 700
				font-size: 20px
			.content
				padding: 18.5px
				background: #fff
				table
					width: 100%
					tr
						td
							padding: 15px 0
							vertical-align: middle
							span
								display: block
								+ span
									margin-top: 15px
							&:first-child
								font-weight: 700
							&:nth-child(2)
								text-align: right
				table:nth-child(2)
					border-top: 1px solid $mainColor
					tr
						+ tr
							td
								border-top: 1px solid #e1e1e1
						&:last-child
							td
								border-top: 1px solid $mainColor
		.btn-group
			display: flex
			margin-left: -20px
			margin-top: 30px
			> *
				margin-left: 20px
				width: 50%
				span
					font-size: 18px
					margin-right: 10px
	@media screen and ( max-width: 1024.98px )
		.other-page
			width: auto
		.wrapper-1
			order: 2
		.wrapper-2
			order: 1
.plan-image-slider
	width: 1410px
	max-width: 100%
	padding: 0
	overflow: visible
	.fancybox-button
		width: 60px
		height: 60px
		svg 
			path
				fill: $mainColor
	.title
		padding: 20px
		text-align: center
		font-weight: 700
		text-transform: uppercase
		font-size: r(20px)
		background-image: $gradient-1
		background-color: transparent
		-webkit-text-fill-color: transparent
		-webkit-background-clip: text
	@media screen and ( min-width: 1600px )
		.swiper-prev
			margin-right: 15px
		.swiper-next
			margin-left: 15px
	@media screen and ( max-width: 576px )
		.fancybox-button
			width: 30px
			height: 30px
			padding: 0
			