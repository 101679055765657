footer.footer-home
	background-image: url(../img/footer-bg.jpg)
	background-repeat: no-repeat
	background-size: cover
	color: #fff
	display: flex
	@media screen and ( min-width: 1600px )
		padding-top: 120px
		.uk-container
			width: 100%
			align-items: flex-end
	.logo
		text-align: right
		svg
			max-height: 185px
	.footer-top
		padding-bottom: 60px
	.footer-content
		.footer-title
			font-size: r(20px)
			text-transform: uppercase
			letter-spacing: 0.1rem
			margin-bottom: r(30px)
			font-weight: 500
		.footer-menu
			ul
				padding-left: 0
				margin-bottom: -20px
				display: flex
				flex-wrap: wrap
				li
					width: 50%
					display: block
					margin-bottom: 20px
		.footer-info
			line-height: 1.4
			ul
				padding-left: 0
				margin-bottom: 0
				li
					display: flex
					+ li
						margin-top: 10px
					span
						margin-right: 10px
						font-size: r(18px)
	.footer-bottom
		padding: 80px 0 150px
		background: rgba($mainColor, .25)
	.form-subscribe
		display: block !important
	.social-link
		margin-top: 20px
		ul
			list-style: none
			padding-left: 0
			margin-bottom: -10px
			margin-left: -40px
			li
				display: inline-flex
				align-items: center
				position: relative
				margin-bottom: 10px
				margin-left: 20px
				padding-left: 20px
				+ li
					&:before
						position: absolute
						content: ''
						width: 1px
						height: 22px
						background: #fff
						left: 0
						top: 50%
						transform: translateY(-50%)
				a
					font-size: 13px
					display: flex
					align-items: center
					span
						margin-right: 10px
						font-size: 20px
	.copyright
		margin-top: 20px
		line-height: 1.3
		p
			font-size: 12px
			margin: 0
			letter-spacing: 0.1rem
	@media screen and ( max-width: 1599.98px )
		.footer-bottom
			padding: 70px 0
	@media screen and ( max-width: 768.98px )
		.footer-bottom
			padding: 40px 0