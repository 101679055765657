.homepage
	.fancybox-inner
		height: 100%
	@media screen and ( max-width: 1600.98px ) and (min-width: 1200px)
		section[class*="home-"]
			.section-title
				font-size: 16px
			.section-lead
				font-size: 24px
				margin-bottom: 10px
			.section-desc
				font-size: 14px
		.home-banner 
			.banner 
				.caption
					p
						white-space: nowrap
		.home-3 
			.tabs-container 
				margin-top: 0
				.uk-switcher
					margin-top: 20px !important
		.home-4 
			.item 
				.icon
					width: 100px !important
					height: 100px !important
					img
						max-width: 40px
				.text
					font-size: 14px !important
		.home-6 
			.section-desc
				margin-bottom: 10px !important
			.item 
				.img
					max-width: 250px
					margin: 0 auto
		.home-7
			padding-top: 60px !important
			.news-list 
				.news-big 
					.img
						+img-scale(56.25%)
				.news-small 
					.img
						padding-top: 56.25%
		footer.footer-home 
			.logo 
				svg
					max-height: 130px
			.social-link 
				margin-top: 15px
			.copyright
				margin-top: 15px
			.footer-content 
				.footer-title
					margin-bottom: 15px !important
					font-size: 18px
				.footer-menu 
					ul 
						margin-bottom: -10px
						li
							margin-bottom: 10px
							a
								font-size: 14px
				.footer-info
					ul
						li
							font-size: 14px
							span
								font-size: 16px
			.footer-top
				padding-bottom: 20px !important
			.wrap-form 
				.frm-btn
					margin-top: 0
				.form-group 
					textarea
						height: 60px
			.footer-bottom
				padding: 15px !important
				.logo
					.img
						max-height: 100px
	@media screen and ( min-width: 1920px )
		.home-2
			> *
				height: 100%
				> *
					height: 100%
					> *
						height: 100%
			.img
				height: 100%
			.content-container
				height: auto !important