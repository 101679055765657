.footer-sub
	padding: 30px 0
	color: #fff
	background: $mainColor
	.logo
		text-align: right
		svg
			max-height: 185px
	.footer-info
		line-height: 1.4
		display: flex
		justify-content: center
		align-items: center
		ul
			padding-left: 0
			margin-bottom: 0
			li
				display: flex
				+ li
					margin-top: 10px
				span
					margin-right: 10px
					font-size: r(18px)
	.social-link
		margin-bottom: 20px
		ul
			display: flex
			align-items: center
			list-style: none
			margin-left: -20px
			margin-bottom: -10px
			padding-left: 0
			flex-wrap: wrap
			li
				padding-left: 20px
				margin-top: 0
				margin-bottom: 10px
				+ li
					display: flex
					align-items: center
					border-left: 1px solid #fff
					margin-left: 20px
				a
					display: flex
					align-items: center
					span
						font-size: r(24px)
						margin-right: 8px
	.copyright
		line-height: 1.4
	@media screen and ( max-width: 1024.98px )
		.logo
			text-align: center
	@media screen and ( max-width: 576px )
		padding-bottom: 50px
		.footer-info
			justify-content: flex-start
		.social-link
			ul
				margin-left: -20px
				justify-content: center
		.copyright
			text-align: center