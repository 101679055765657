.sub-banner
	background: #000
	min-height: 400px
	img
		opacity: 0.5
		height: 100%
		object-fit: cover

.breadcrumb-wrapper
	bottom: 30px
	color: #fff
	.page-title
		padding-bottom: 20px
		position: relative
		&:before
			position: absolute
			content: ''
			height: 1px
			width: 80px
			background: #fff
			bottom: 20px
			left: 50%
			transform: translateX(-50%)
		span
			color: #fff
			font-family: $font-1 !important
			font-weight: 900
			text-transform: uppercase
			font-size: r(60px)
			margin-bottom: 20px
			display: block
	.breadcrumb
		display: flex
		list-style: none
		padding-left: 0
		margin-bottom: 0
		justify-content: center
		li
			display: flex
			a
				color: #cccccc
				font-size: 14px
				letter-spacing: 0.1rem
			+ li
				margin-left: 10px
				align-items: center
				&:before
					font-family: remixicon
					content: "\EA6E"
					margin-right: 10px
			&:last-child
				a
					color: #fff
	@media screen and ( max-width: 768px )
		bottom: auto
		top: 50%
		transform: translate(-50%,-50%)
		.breadcrumb
			display: none