.mat-bang-1
	// .image-map
	// 	overflow-x: auto
	// 	max-width: 100%
	// 	img
	// 		max-width: none

.plan-tooltip
	min-width: 100px
	height: 40px
	border-radius: 25px
	padding: 0 20px
	background: $gradient-1
	color: #fff
	text-transform: uppercase
	font-weight: 500
	font-size: 14px
	text-align: center
	line-height: 40px