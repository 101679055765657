.home-6
	background-image: url(../img/home/bg-6.png)
	background-repeat: no-repeat
	background-position: right top
	.section-desc
		max-width: 800px
		margin: 0 auto 30px
	.item
		display: block
		text-align: center
		&:hover
			.img
				&:after
					opacity: 1
		.img
			margin-bottom: 20px
			position: relative
			text-align: center
			&:after
				content: url(../img/search-icon.png)
				position: absolute
				top: 50%
				left: 50%
				transform: translate(-50%,-50%)
				z-index: 3
				transition: 0.3s all
				opacity: 0
		.name 
			font-size: r(18px)
			line-height: 1.4
			text-transform: uppercase
			font-weight: 700
			margin-bottom: 10px
		.desc
			p
				margin: 0
				line-height: 1.3
				font-size: 15px