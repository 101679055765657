.tien-ich
	.image
		padding-right: 0
		overflow: hidden
		border-radius: 20px 0 0 20px
		img
			width: 100%
			height: 100%
			object-fit: cover
	.content
		background-image: url(../img/home/bg-2.png)
		background-repeat: no-repeat
		background-size: cover
		border-radius: 0 20px 20px 0
		padding: 60px
		.section-lead
			margin: 15px 0 30px 0
@media (max-width: 1024.98px)
	.tien-ich
		.image
			padding: 0 15px
			img
				border-radius: 20px 20px 0 0
		.content
			margin: 0 15px
			padding: 30px
			border-radius: 0 0 20px 20px