.modulepager
	.pagination
		margin-top: 30px
		margin-bottom: 30px
		display: flex
		list-style-type: none
		justify-content: center
		padding: 0
		li
			margin: 0 5px
			&:last-child
				margin-right: 0
			a, span
				font-size: 18px
				width: 40px
				background: #fff
				border: 1px solid #6ebeea
				color: #333
				height: 40px
				display: flex
				justify-content: center
				align-items: center
				transition: .4s ease-in-out all
				border-radius: 50%
			.LastPage,.FirstPage
				display: none!important
			.NextPage
				font-size: 0
				position: relative
				&:before
					content: '\f054'
					font-family: Material Design Icons
					position: absolute
					top: 50%
					font-size: 18px
					transform: translateY(-50%)

			.BackPage
				font-size: 0
				position: relative
				&:before
					content: '\f04d'
					font-family: Material Design Icons
					position: absolute
					top: 50%
					left: 50%
					color: $mainColor
					font-size: 18px
					transform: translate(-50%,-50%)
			&.active, &:hover
				a, span
					color: #ffffff
					background: #6ebeea
					&:before
						color: #ffffff