.noi-khu-1
	.image-map
		margin-top: 40px
	
	.tien-ich-chu-thich
		background: #f8f8f8
		.uk-grid-margin
			margin-top: 20px
		.item
			display: flex
			align-items: center
			.number
				width: 60px
				height: 60px
				display: flex
				align-items: center
				justify-content: center
				border-radius: 50%
				background: #fff
				color: $mainColor
				border: 1px solid $mainColor
				font-size: 24px
				font-weight: 900
				font-family: $font-1
			.name
				padding-left: 10px
				line-height: 1.3
	
	
	
.noi-khu-2
	.box
		align-items: center
		+ .box
			margin-top: 80px
		.image
			overflow: hidden
			border-radius: 20px
			img
				width: 100%
				height: 100%
				object-fit: cover
		.content
			padding: 50px 0 50px 50px
		&:nth-child(2n)
			flex-direction: row-reverse
			.content
				padding: 50px 50px 50px 0
	@media screen and ( max-width: 1024.98px )
		.box
			+ .box
				margin-top: 0
			.content
				padding: 30px !important
	@media screen and ( max-width: 768px )
		.section-lead
			br
				display: none