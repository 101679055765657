// Fontawesome icons
.fa-bars:before {
	content: '\f0c9'; }
.fa-user:before {
	content: '\f007'; }
.fa-shopping-cart:before {
	content: '\f07a'; }
.fa-facebook-f:before {
	content: '\f39e'; }
.fa-facebook-square:before {
	content: '\f082'; }
.fa-apple:before {
	content: '\f179'; }
.fa-instagram:before {
	content: '\f16d'; }
.fa-youtube:before {
	content: '\f167'; }
.fa-home:before {
	content: '\f015'; }
.fa-envelope:before {
	content: '\f0e0'; }
.fa-clock:before {
	content: '\f017'; }
.fa-phone-volume:before {
	content: '\f2a0'; }
.fa-quote-left:before {
	content: '\f10e'; }
.fa-quote-right:before {
	content: '\f10d'; }
.fa-key:before {
	content: "\f084"; }
.fa-folder-open:before {
	content: "\f07c"; }
.fa-cog:before {
	content: "\f013"; }
.fa-edit:before {
	content: "\f044"; }
.fa-file:before {
	content: "\f15b"; }
.fa-chevron-circle-right:before {
	content: "\f138"; }
.fa-pencil:before {
	content: "\f303"; }
.fa-map-marker-alt:before {
	content: '\f3c5'; }


// Material icons
.mdi-account:before {
	content: '\f004'; }
.mdi-magnify:before {
	content: '\f349'; }
.mdi-home:before {
	content: '\f2dc'; }
.mdi-close:before {
	content: '\f156'; }
.mdi-menu:before {
	content: '\f35c'; }
.mdi-phone:before {
	content: '\f3f2'; }
.mdi-email:before {
	content: '\f1ee'; }
.mdi-plus-circle:before {
	content: '\f417'; }
.mdi-alert-circle-outline:before {
	content: '\f5d6'; }
.mdi-calendar:before {
	content: '\f0ed'; }
.mdi-map-marker:before {
	content: '\f34e'; }
.mdi-chevron-double-left:before {
	content: '\f13d'; }
.mdi-chevron-double-right:before {
	content: '\f13e'; }
.mdi-cloud-download:before {
	content: '\f162'; }
.mdi-briefcase:before {
	content: '\f0d6'; }
.mdi-fax:before {
	content: '\f212'; }
.mdi-whatsapp:before {
	content: '\f5a3'; }
.mdi-map:before {
	content: '\f34d'; }
.mdi-printer:before {
	content: '\f42a'; }
.mdi-file-outline:before {
	content: '\f224'; }
.mdi-chevron-down:before {
	content: '\f140'; }
.mdi-link:before {
	content: '\f337'; }
.mdi-arrow-right:before {
	content: '\f054'; }
.mdi-android:before {
	content: '\f032'; }
.mdi-chevron-right:before {
	content: '\f142'; }
.mdi-arrow-left:before {
	content: '\f04d'; }
.mdi-arrow-up:before {
	content: '\f05d'; }

// Linearicons icons
.lnr-arrow-right:before {
	content: '\e87a'; }
.lnr-arrow-left:before {
	content: '\e879'; }
.lnr-chevron-right:before {
	content: '\e876'; }
.lnr-chevron-left:before {
	content: '\e875'; }
.lnr-chevron-down:before {
	content: '\e874'; }
.lnr-chevron-up:before {
	content: '\e873'; }
.lnr-download:before {
	content: '\e865'; }

.ri-home-4-line:before {
	content: "\EE1D"; }
.ri-map-pin-2-line:before {
	content: "\EF0A"; }
.ri-phone-line:before {
	content: "\EFEC"; }
.ri-mail-line:before {
	content: "\EEF6"; }
.ri-map-pin-2-line:before {
	content: "\EF0A"; }
.ri-door-open-fill:before {
	content: "\EC4B"; }
.ri-edit-2-line:before {
	content: "\EC80"; }
